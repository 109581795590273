/**
 * This file houses all the updated objects for the prepaid promos that
 * were retrieved from the display promo catalog;
 *
 * Updated include the modification of the short description to align it
 * with the FIGMA expectationß
 */
import prodPromos from "./prod/top5PrepaidPromo.json";
import prodBoosters from "./prod/goBoosterPromos.json";

// sort by rank_sku
prodPromos.sort(function (a, b) {
   const aRankSku = parseInt(a.rank_sku);
   const bRankSku = parseInt(b.rank_sku);

   if (aRankSku < bRankSku) return -1;
   if (aRankSku > bRankSku) return 1;
   return 0;
});

export const PREPAID_PROMOS_DATA = prodPromos;
export const PREPAID_BOOSTERS_DATA = prodBoosters;

// export const PREPAID_PROMOS_DATA = [
//   {
//     entity_id: "29017",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "SUPERXCLUSIVE!Goplus99-mainstream-10555-GHP-Prepaid-Merida",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2023-05-09 03:30:27",
//     updated_at: "2023-05-15 13:50:34",
//     row_id: "29018",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "10GB data + 8GB choice of apps + Unli allnet text. Valid for 8 days.",
//     meta_keyword: "SUPER XCLUSIVE! Go+99",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash",
//     usage_category: "App Data",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "10555",
//         charging: "0",
//         nf_main_freebie_freebie_type: "Additional 2GB of Data",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_id: "10554",
//         charging: "0",
//         nf_main_freebie_freebie_type:
//           "Additional 1 day Validity (Total of 8 Days)",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "XGOP9934",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "XGOP9935",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "2",
//         nf_service_param: "XGOP9936",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "3",
//         nf_service_param: "XGOP9937",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "4",
//         nf_service_param: "XGOP9938",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "5",
//         nf_service_param: "XGOP9939",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLISTEN|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "6",
//         nf_service_param: "XGOP9940",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "7",
//         nf_service_param: "XGOP9941",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "8",
//         nf_service_param: "XGOP9942",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoKOREAN|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         nf_service_param: "XGOP9943",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         nf_service_param: "XGOP9944",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "11",
//         nf_service_param: "XGOP9959",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSELL|Php50 Lazada voucher",
//         initialize: "1",
//       },
//       {
//         record_id: "12",
//         nf_service_param: "XGOP9961",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "13",
//         nf_service_param: "XGOP9962",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "14",
//         nf_service_param: "XGOP9963",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "15",
//         nf_service_param: "XGOP9964",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "16",
//         nf_service_param: "XGOP9965",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "17",
//         nf_service_param: "XGOP9966",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLISTEN|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "18",
//         nf_service_param: "XGOP9967",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "19",
//         nf_service_param: "XGOP9968",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "20",
//         nf_service_param: "XGOP9969",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoKOREAN|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "21",
//         nf_service_param: "XGOP9970",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "22",
//         nf_service_param: "XGOP9971",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "23",
//         nf_service_param: "XGOP9972",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSELL|Php300 voucher on Parlon Deals",
//         initialize: "1",
//       },
//       {
//         record_id: "24",
//         nf_service_param: "XGOP9976",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "25",
//         nf_service_param: "XGOP9977",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "26",
//         nf_service_param: "XGOP9978",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "27",
//         nf_service_param: "XGOP9979",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "28",
//         nf_service_param: "XGOP9980",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "29",
//         nf_service_param: "XGOP9981",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLISTEN|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "30",
//         nf_service_param: "XGOP9982",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "31",
//         nf_service_param: "XGOP9983",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "32",
//         nf_service_param: "XGOP9984",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoKOREAN|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "33",
//         nf_service_param: "XGOP9985",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "34",
//         nf_service_param: "XGOP9986",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "35",
//         nf_service_param: "XGOP9987",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSELL|5% off on Klook Activities",
//         initialize: "1",
//       },
//       {
//         record_id: "36",
//         nf_service_param: "XGOP9991",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoWATCH|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "37",
//         nf_service_param: "XGOP9992",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoPLAY|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "38",
//         nf_service_param: "XGOP9993",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSHARE|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "39",
//         nf_service_param: "XGOP9994",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLEARN|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "40",
//         nf_service_param: "XGOP9995",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoWORK|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "41",
//         nf_service_param: "XGOP9996",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLISTEN|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "42",
//         nf_service_param: "XGOP9997",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSHOP|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "43",
//         nf_service_param: "XGOP9998",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoRIDE|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "44",
//         nf_service_param: "XGOP9999",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoKOREAN|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "45",
//         nf_service_param: "XGOP10000",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoPINOY|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "46",
//         nf_service_param: "XGOP10001",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoANIME|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "47",
//         nf_service_param: "XGOP10002",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSELL|Php60 AirAsia voucher on Hotel Bookings",
//         initialize: "1",
//       },
//       {
//         record_id: "48",
//         nf_service_param: "XGOP10006",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoWATCH|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "49",
//         nf_service_param: "XGOP10007",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoPLAY|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "50",
//         nf_service_param: "XGOP10008",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSHARE|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "51",
//         nf_service_param: "XGOP10009",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLEARN|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "52",
//         nf_service_param: "XGOP10010",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoWORK|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "53",
//         nf_service_param: "XGOP10011",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLISTEN|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "54",
//         nf_service_param: "XGOP10012",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSHOP|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "55",
//         nf_service_param: "XGOP10013",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoRIDE|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "56",
//         nf_service_param: "XGOP10014",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoKOREAN|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "57",
//         nf_service_param: "XGOP10015",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoPINOY|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "58",
//         nf_service_param: "XGOP10016",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoANIME|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "59",
//         nf_service_param: "XGOP10017",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoSELL|Php200 voucher on Pick.A.Roo Shops",
//         initialize: "1",
//       },
//       {
//         record_id: "60",
//         nf_service_param: "XGOP10021",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "61",
//         nf_service_param: "XGOP10022",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "62",
//         nf_service_param: "XGOP10023",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "63",
//         nf_service_param: "XGOP10024",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "64",
//         nf_service_param: "XGOP10025",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "65",
//         nf_service_param: "XGOP10026",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoLISTEN|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "66",
//         nf_service_param: "XGOP10027",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "67",
//         nf_service_param: "XGOP10028",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "68",
//         nf_service_param: "XGOP10029",
//         charging: "0",
//         nf_main_param_freebie_freebie:
//           "GoKOREAN|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "69",
//         nf_service_param: "XGOP10030",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "70",
//         nf_service_param: "XGOP10031",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//       {
//         record_id: "71",
//         nf_service_param: "XGOP10032",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSELL|Php50 voucher on GrabCar/Share",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_name: "GoWATCH",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "1",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"HBO Go","icon":"/c/o/colored_28.png"}',
//           '{"label":"NBA","icon":"/c/o/colored_29.png"}',
//           '{"label":"Disney+","icon":"/c/o/content-disneyplus_1_1.png"}',
//         ],
//       },
//       {
//         record_id: "1",
//         freebie_name: "GoPLAY",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "2",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"Mobile Legends","icon":"/c/o/colored_1_15.png"}',
//           '{"label":"Pokemon Unite","icon":"/p/o/pokemon_unite.png"}',
//           '{"label":"Genshin Impact","icon":"/g/e/genshinimpacthoyoverseicon.jpg"}',
//           '{"label":"PUBG","icon":"/c/o/colored_1_17.png"}',
//           '{"label":"Call of Duty","icon":"/c/o/colored_1_28.png"}',
//           '{"label":"Wild Rift","icon":"/c/o/colored_1_27.png"}',
//           '{"label":"Legends of Runeterra","icon":"/c/o/colored_1_20.png"}',
//         ],
//       },
//       {
//         record_id: "2",
//         freebie_name: "GoSHARE",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "3",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"Facebook","icon":"/c/o/colored_14.png"}',
//           '{"label":"Instagram","icon":"/c/o/colored_1_29.png"}',
//           '{"label":"TikTok","icon":"/c/o/colored_15.png"}',
//           '{"label":"Twitch","icon":"/c/o/colored_16.png"}',
//           '{"label":"Discord","icon":"/v/i/viber_image_2023-01-09_14-04-06-342_1.png"}',
//           '{"label":"Twitter","icon":"/c/o/colored_17.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Snapchat","icon":"/c/o/colored_18.png"}',
//         ],
//       },
//       {
//         record_id: "3",
//         freebie_name: "GoLEARN",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "4",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"YouTube Learning","icon":"/y/o/youtube_learning.png"}',
//           '{"label":"Udemy","icon":"/c/o/colored_1_21.png"}',
//           '{"label":"Google Suite","icon":"/g/o/google-suite.png"}',
//           '{"label":"Edukasyon","icon":"/e/d/edukasyon.png"}',
//           '{"label":"Canva","icon":"/c/o/colored_1_22.png"}',
//         ],
//       },
//       {
//         record_id: "4",
//         freebie_name: "GoWORK",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "5",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"Zoom","icon":"/c/o/colored_30.png"}',
//           '{"label":"FB Meeting Room","icon":"/f/b/fb_messenger.png"}',
//           '{"label":"Yahoo! Mail","icon":"/c/o/colored_31.png"}',
//           '{"label":"MS Teams","icon":"/c/o/colored_32.png"}',
//           '{"label":"WhatsApp","icon":"/c/o/colored_33.png"}',
//           '{"label":"Viber","icon":"/c/o/colored_1_10.png"}',
//           '{"label":"Telegram","icon":"/c/o/colored_1_11.png"}',
//         ],
//       },
//       {
//         record_id: "5",
//         freebie_name: "GoLISTEN",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "6",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"YouTube Music","icon":"/c/o/colored_1_13.png"}',
//           '{"label":"Spotify","icon":"/c/o/colored_1_14.png"}',
//           '{"label":"WeSing","icon":"/c/o/colored_1_49.png"}',
//         ],
//       },
//       {
//         record_id: "6",
//         freebie_name: "GoSHOP",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "7",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"Lazada","icon":"/c/o/colored_22.png"}',
//           '{"label":"Shopee","icon":"/c/o/colored_23.png"}',
//           '{"label":"ZALORA","icon":"/c/o/colored_24.png"}',
//           '{"label":"BeautyMNL","icon":"/b/e/beautymnl.png"}',
//           '{"label":"Grab Food","icon":"/c/o/colored_25.png"}',
//           '{"label":"Foodpanda","icon":"/c/o/colored_26.png"}',
//         ],
//       },
//       {
//         record_id: "7",
//         freebie_name: "GoRIDE",
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "8",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//         freebie_apps: [
//           '{"label":"Grab","icon":"/c/o/colored_19.png"}',
//           '{"label":"Google Maps","icon":"/c/o/colored_20.png"}',
//           '{"label":"Waze","icon":"/c/o/colored_21.png"}',
//         ],
//       },
//       {
//         record_id: "8",
//         freebie_name: "GoKOREAN",
//         freebie_apps: [
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"Viki","icon":"/c/o/colored_13_2.png"}',
//         ],
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "9",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         freebie_name: "GoPINOY",
//         freebie_apps: [
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Vivamax","icon":"/c/o/colored_1_24.png"}',
//         ],
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "11",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         freebie_name: "GoANIME",
//         freebie_apps: [
//           '{"label":"Crunchyroll","icon":"/c/r/crunchyroll.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"iQiyi","icon":"/c/o/colored_13_4.png"}',
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//         ],
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "12",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "11",
//         freebie_name: "GoSELL",
//         freebie_apps: [
//           '{"label":"Facebook","icon":"/c/o/colored_14.png"}',
//           '{"label":"Instagram","icon":"/c/o/colored_1_29.png"}',
//           '{"label":"Viber","icon":"/c/o/colored_1_10.png"}',
//           '{"label":"Lazada","icon":"/c/o/colored_22.png"}',
//           '{"label":"Shopee","icon":"/c/o/colored_23.png"}',
//           '{"label":"Grab","icon":"/c/o/colored_19.png"}',
//           '{"label":"Carousell","icon":"/c/o/colored_1_25.png"}',
//         ],
//         freebie_category: "Entertainment",
//         freebie_type: "",
//         freebie_param: "21",
//         freebie_description: "",
//         freebie_allocation: "8GB",
//         freebie_validity: "7days",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "12",
//         freebie_name: "Php50 Lazada voucher",
//         freebie_apps: ['{"label":"Lazada","icon":"/c/o/colored_22.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "19",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "13",
//         freebie_name: "Php300 voucher on Parlon Deals",
//         freebie_apps: ['{"label":"Parlon","icon":"/p/a/parlon.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "22",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "14",
//         freebie_name: "5% off on Klook Activities",
//         freebie_apps: ['{"label":"Klook","icon":"/k/l/klook.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "23",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "15",
//         freebie_name: "Php60 AirAsia voucher on Hotel Bookings",
//         freebie_apps: ['{"label":"AirAsia","icon":"/a/i/airasia.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "24",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "16",
//         freebie_name: "Php200 voucher on Pick.A.Roo Shops",
//         freebie_apps: ['{"label":"Pickaroo","icon":"/p/i/pickaroo.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "25",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "17",
//         freebie_name: "Php50 voucher on GrabCar/Share",
//         freebie_apps: ['{"label":"Grab","icon":"/c/o/colored_19.png"}'],
//         freebie_category: "Lifestyle",
//         freebie_type: "",
//         freebie_param: "26",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "SUPER XCLUSIVE! Go+99",
//     meta_title: "SUPER XCLUSIVE! Go+99",
//     meta_description: "SUPER XCLUSIVE! Go+99 ",
//     page_layout: "Product -- Full Width",
//     options_container: "Block after Info Column",
//     url_key: "superxclusive-goplus99-mainstream-10555-ghp-prepaid-merida",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "Use config",
//     is_returnable: "",
//     validity: "7",
//     sms_allocation: "Unli Allnet Texts",
//     skellig_keyword: "PR_PREGS8GB7DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "10GB",
//     app_data_allocation: "8GB",
//     data_validity: "7",
//     total_data_allocation: "18GB",
//     additional_app_perks: "Free 2GB or Free additional 1 day validity",
//     app_data_title: "8GB choice of apps",
//     featured_category: "GlobeOne exclusives",
//     rank_sku: "11",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     tax_class_id: "Taxable Goods",
//     is_featured: "Yes",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     freebie_selection_type: "Vancouver",
//     is_booster_allowed: "No",
//     is_limited: "No",
//     promo_category: "Go+",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "Yes",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "Yes",
//     price: 99,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     quantity_and_stock_status: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     calls_allocation: "",
//     data_allocation: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_subscribe: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
//   {
//     entity_id: "4468",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "SUPERXCLUSIVE!UnliGo149-mainstream-10226-GHP-Prepaid|GHP MyFi",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2022-10-19 16:13:58",
//     updated_at: "2023-05-15 13:50:34",
//     row_id: "4469",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description: "5GB data + UNLI data choice of apps. Valid for 7 days",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "10226",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "1491",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "1492",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY",
//         initialize: "1",
//       },
//       {
//         record_id: "2",
//         nf_service_param: "1493",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE",
//         initialize: "1",
//       },
//       {
//         record_id: "3",
//         nf_service_param: "1494",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN",
//         initialize: "1",
//       },
//       {
//         record_id: "4",
//         nf_service_param: "1495",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK",
//         initialize: "1",
//       },
//       {
//         record_id: "5",
//         nf_service_param: "1496",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLISTEN",
//         initialize: "1",
//       },
//       {
//         record_id: "6",
//         nf_service_param: "1497",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP",
//         initialize: "1",
//       },
//       {
//         record_id: "7",
//         nf_service_param: "1498",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE",
//         initialize: "1",
//       },
//       {
//         record_id: "8",
//         nf_service_param: "1499",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoKOREAN",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         nf_service_param: "14911",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         nf_service_param: "14912",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_name: "GoWATCH",
//         freebie_apps: [
//           '{"label":"YouTube","icon":null}',
//           '{"label":"Netflix","icon":null}',
//           '{"label":"Viu","icon":null}',
//           '{"label":"iWantTFC","icon":null}',
//           '{"label":"HBO Go","icon":null}',
//           '{"label":"NBA","icon":null}',
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"HBO Go","icon":"/c/o/colored_28.png"}',
//           '{"label":"NBA","icon":"/c/o/colored_29.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "1",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         freebie_name: "GoPLAY",
//         freebie_apps: [
//           '{"label":"Mobile Legends","icon":null}',
//           '{"label":"Clash of Clans","icon":null}',
//           '{"label":"PUBG","icon":null}',
//           '{"label":"Call of Duty","icon":null}',
//           '{"label":"MU Origin 2","icon":null}',
//           '{"label":"Wild Rift","icon":null}',
//           '{"label":"Legends of Runeterra","icon":null}',
//           '{"label":"Twitch","icon":null}',
//           '{"label":"Mobile Legends","icon":"/c/o/colored_1_15.png"}',
//           '{"label":"PUBG","icon":"/c/o/colored_1_17.png"}',
//           '{"label":"Call of Duty","icon":"/c/o/colored_1_28.png"}',
//           '{"label":"Wild Rift","icon":"/c/o/colored_1_27.png"}',
//           '{"label":"Legends of Runeterra","icon":"/c/o/colored_1_20.png"}',
//           '{"label":"Genshin Impact","icon":"/g/e/genshinimpacthoyoverseicon.jpg"}',
//           '{"label":"Pokemon Unite","icon":"/p/o/pokemon_unite.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "2",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "2",
//         freebie_name: "GoSHARE",
//         freebie_apps: [
//           '{"label":"Facebook","icon":null}',
//           '{"label":"Instagram","icon":null}',
//           '{"label":"TikTok","icon":null}',
//           '{"label":"Twitter","icon":null}',
//           '{"label":"Kumu","icon":null}',
//           '{"label":"Snapchat","icon":null}',
//           '{"label":"Facebook","icon":"/c/o/colored_14.png"}',
//           '{"label":"Instagram","icon":"/c/o/colored_1_29.png"}',
//           '{"label":"TikTok","icon":"/c/o/colored_15.png"}',
//           '{"label":"Twitter","icon":"/c/o/colored_17.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Snapchat","icon":"/c/o/colored_18.png"}',
//           '{"label":"Houseparty","icon":null}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "3",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "3",
//         freebie_name: "GoLEARN",
//         freebie_apps: [
//           '{"label":"Udemy","icon":null}',
//           '{"label":"Canva","icon":null}',
//           '{"label":"Udemy","icon":"/c/o/colored_1_21.png"}',
//           '{"label":"Canva","icon":"/c/o/colored_1_22.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "4",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "4",
//         freebie_name: "GoWORK",
//         freebie_apps: [
//           '{"label":"Zoom","icon":null}',
//           '{"label":"Yahoo! Mail","icon":null}',
//           '{"label":"MS Teams","icon":null}',
//           '{"label":"WhatsApp","icon":null}',
//           '{"label":"Viber","icon":null}',
//           '{"label":"Telegram","icon":null}',
//           '{"label":"Zoom","icon":"/c/o/colored_30.png"}',
//           '{"label":"MS Teams","icon":"/c/o/colored_32.png"}',
//           '{"label":"WhatsApp","icon":"/c/o/colored_33.png"}',
//           '{"label":"Viber","icon":"/c/o/colored_1_10.png"}',
//           '{"label":"Telegram","icon":"/c/o/colored_1_11.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "5",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "5",
//         freebie_name: "GoLISTEN",
//         freebie_apps: [
//           '{"label":"YouTube Music","icon":null}',
//           '{"label":"Spotify","icon":null}',
//           '{"label":"SoundCloud","icon":null}',
//           '{"label":"Spotify","icon":"/c/o/colored_1_14.png"}',
//           '{"label":"WeSing","icon":null}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "6",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "6",
//         freebie_name: "GoSHOP",
//         freebie_apps: [
//           '{"label":"Lazada","icon":null}',
//           '{"label":"Shopee","icon":null}',
//           '{"label":"ZALORA","icon":null}',
//           '{"label":"Grab Food","icon":null}',
//           '{"label":"Foodpanda","icon":null}',
//           '{"label":"Lazada","icon":"/c/o/colored_22.png"}',
//           '{"label":"Shopee","icon":"/c/o/colored_23.png"}',
//           '{"label":"ZALORA","icon":"/c/o/colored_24.png"}',
//           '{"label":"Grab Food","icon":"/c/o/colored_25.png"}',
//           '{"label":"Foodpanda","icon":"/c/o/colored_26.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "7",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "7",
//         freebie_name: "GoRIDE",
//         freebie_apps: [
//           '{"label":"Grab","icon":null}',
//           '{"label":"Google Maps","icon":null}',
//           '{"label":"Waze","icon":null}',
//           '{"label":"Grab","icon":"/c/o/colored_19.png"}',
//           '{"label":"Google Maps","icon":"/c/o/colored_20.png"}',
//           '{"label":"Waze","icon":"/c/o/colored_21.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "8",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "8",
//         freebie_name: "GoKOREAN",
//         freebie_apps: [
//           '{"label":"Viu","icon":null}',
//           '{"label":"Viki","icon":null}',
//           '{"label":"YouTube","icon":null}',
//           '{"label":"Netflix","icon":null}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"V Live","icon":null}',
//           '{"label":"Viki","icon":"/c/o/colored_13_2.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "9",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         freebie_name: "GoPINOY",
//         freebie_apps: [
//           '{"label":"Kumu","icon":null}',
//           '{"label":"iWantTFC","icon":null}',
//           '{"label":"Vivamax","icon":null}',
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Vivamax","icon":"/c/o/colored_1_24.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "11",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         freebie_name: "GoANIME",
//         freebie_apps: [
//           '{"label":"Netflix","icon":null}',
//           '{"label":"Viu","icon":null}',
//           '{"label":"iQiyi","icon":null}',
//           '{"label":"YouTube","icon":null}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"WeTV","icon":null}',
//           '{"label":"iQiyi","icon":"/c/o/colored_13_4.png"}',
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Crunchyroll","icon":null}',
//         ],
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "12",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "7 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "UnliGo149",
//     options_container: "Block after Info Column",
//     url_key: "superxclusive-unligo149-mainstream-10226-ghp-prepaid-ghp-myfi",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "No",
//     is_returnable: "",
//     validity: "7",
//     skellig_keyword: "PR_PREGS5GB7DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer, SG",
//     mobile_allocation: "5GB",
//     total_data_allocation: "5GB mobile data",
//     additional_app_perks: "Unli data for your app of choice",
//     featured_category: "GlobeOne exclusives",
//     rank_sku: "14",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     quantity_and_stock_status: "In Stock",
//     tax_class_id: "Taxable Goods",
//     is_featured: "Yes",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "LTP",
//     freebie_selection_type: "Mainstream",
//     is_booster_allowed: "No",
//     is_limited: "No",
//     promo_category: "SUPER XCLUSIVE!",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "Yes",
//     price: 149,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     meta_title: "",
//     meta_keyword: "",
//     meta_description: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     page_layout: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     usage_category: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     sms_allocation: "",
//     calls_allocation: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_subscribe: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
//   {
//     entity_id: "5685",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "SUPERXCLUSIVE!UnliGo299-mainstream-10272-GHP-Prepaid|GHP MyFi",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2023-02-17 03:40:36",
//     updated_at: "2023-05-15 13:50:34",
//     row_id: "5686",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "10GB data + UNLI data choice of apps. Valid for 15 days",
//     meta_keyword: "SUPER XCLUSIVE! UnliGo299",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "10235",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "2991",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWATCH",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "2992",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPLAY",
//         initialize: "1",
//       },
//       {
//         record_id: "2",
//         nf_service_param: "2993",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHARE",
//         initialize: "1",
//       },
//       {
//         record_id: "3",
//         nf_service_param: "2994",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLEARN",
//         initialize: "1",
//       },
//       {
//         record_id: "4",
//         nf_service_param: "2995",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoWORK",
//         initialize: "1",
//       },
//       {
//         record_id: "5",
//         nf_service_param: "2996",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoLISTEN",
//         initialize: "1",
//       },
//       {
//         record_id: "6",
//         nf_service_param: "2997",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoSHOP",
//         initialize: "1",
//       },
//       {
//         record_id: "7",
//         nf_service_param: "2998",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoRIDE",
//         initialize: "1",
//       },
//       {
//         record_id: "8",
//         nf_service_param: "2999",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoKOREAN",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         nf_service_param: "29911",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoPINOY",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         nf_service_param: "29912",
//         charging: "0",
//         nf_main_param_freebie_freebie: "GoANIME",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_name: "GoWATCH",
//         freebie_apps: [
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"HBO Go","icon":"/c/o/colored_28.png"}',
//           '{"label":"NBA","icon":"/c/o/colored_29.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "1",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         freebie_name: "GoPLAY",
//         freebie_apps: [
//           '{"label":"Mobile Legends","icon":"/c/o/colored_1_15.png"}',
//           '{"label":"PUBG","icon":"/c/o/colored_1_17.png"}',
//           '{"label":"Call of Duty","icon":"/c/o/colored_1_28.png"}',
//           '{"label":"Wild Rift","icon":"/c/o/colored_1_27.png"}',
//           '{"label":"Legends of Runeterra","icon":"/c/o/colored_1_20.png"}',
//           '{"label":"Genshin Impact","icon":"/g/e/genshinimpacthoyoverseicon.jpg"}',
//           '{"label":"Pokemon Unite","icon":"/p/o/pokemon_unite.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "2",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "2",
//         freebie_name: "GoSHARE",
//         freebie_apps: [
//           '{"label":"Facebook","icon":"/c/o/colored_14.png"}',
//           '{"label":"Instagram","icon":"/c/o/colored_1_29.png"}',
//           '{"label":"TikTok","icon":"/c/o/colored_15.png"}',
//           '{"label":"Twitter","icon":"/c/o/colored_17.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Snapchat","icon":"/c/o/colored_18.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "3",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "3",
//         freebie_name: "GoLEARN",
//         freebie_apps: [
//           '{"label":"Udemy","icon":"/c/o/colored_1_21.png"}',
//           '{"label":"Canva","icon":"/c/o/colored_1_22.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "4",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "4",
//         freebie_name: "GoWORK",
//         freebie_apps: [
//           '{"label":"Zoom","icon":"/c/o/colored_30.png"}',
//           '{"label":"MS Teams","icon":"/c/o/colored_32.png"}',
//           '{"label":"WhatsApp","icon":"/c/o/colored_33.png"}',
//           '{"label":"Viber","icon":"/c/o/colored_1_10.png"}',
//           '{"label":"Telegram","icon":"/c/o/colored_1_11.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "5",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "5",
//         freebie_name: "GoLISTEN",
//         freebie_apps: [
//           '{"label":"Spotify","icon":"/c/o/colored_1_14.png"}',
//           '{"label":"SoundCloud","icon":null}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "6",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "6",
//         freebie_name: "GoSHOP",
//         freebie_apps: [
//           '{"label":"Lazada","icon":"/c/o/colored_22.png"}',
//           '{"label":"Shopee","icon":"/c/o/colored_23.png"}',
//           '{"label":"ZALORA","icon":"/c/o/colored_24.png"}',
//           '{"label":"Foodpanda","icon":"/c/o/colored_26.png"}',
//           '{"label":"Grab Food","icon":"/c/o/colored_25.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "7",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "7",
//         freebie_name: "GoRIDE",
//         freebie_apps: [
//           '{"label":"Grab","icon":"/c/o/colored_19.png"}',
//           '{"label":"Google Maps","icon":"/c/o/colored_20.png"}',
//           '{"label":"Waze","icon":"/c/o/colored_21.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "8",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "8",
//         freebie_name: "GoKOREAN",
//         freebie_apps: [
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"V Live","icon":"/c/o/colored_1_46.png"}',
//           '{"label":"Viki","icon":"/c/o/colored_13_2.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "9",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "9",
//         freebie_name: "GoPINOY",
//         freebie_apps: [
//           '{"label":"iWantTFC","icon":"/c/o/colored_1_23.png"}',
//           '{"label":"Kumu","icon":"/c/o/colored-1_1.png"}',
//           '{"label":"Vivamax","icon":"/c/o/colored_1_24.png"}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "11",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//       {
//         record_id: "10",
//         freebie_name: "GoANIME",
//         freebie_apps: [
//           '{"label":"YouTube","icon":"/c/o/colored_1_9.png"}',
//           '{"label":"Netflix","icon":"/c/o/colored_13.png"}',
//           '{"label":"Viu","icon":"/c/o/colored_13_1.png"}',
//           '{"label":"WeTV","icon":"/c/o/colored_1_47.png"}',
//           '{"label":"iQiyi","icon":"/c/o/colored_13_4.png"}',
//           '{"label":"Crunchyroll","icon":null}',
//         ],
//         freebie_category: "",
//         freebie_type: "Geralt",
//         freebie_param: "12",
//         freebie_description: "",
//         freebie_allocation: "UNLI",
//         freebie_validity: "15 DAYS",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "UnliGo299",
//     meta_title: "SUPER XCLUSIVE! UnliGo299",
//     meta_description: "SUPER XCLUSIVE! UnliGo299 ",
//     page_layout: "Product -- Full Width",
//     options_container: "Block after Info Column",
//     url_key: "superxclusiveunligo299-mainstream-ghp-prepaid-ghp-myfi",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "Use config",
//     is_returnable: "",
//     validity: "15",
//     skellig_keyword: "PR_PREGS10GB15DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "10GB",
//     total_data_allocation: "10GB mobile data",
//     additional_app_perks: "Unli data for your app of choice",
//     featured_category: "GlobeOne exclusives",
//     rank_sku: "17",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     tax_class_id: "Taxable Goods",
//     is_featured: "Yes",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     freebie_selection_type: "Mainstream",
//     is_booster_allowed: "No",
//     is_limited: "No",
//     promo_category: "SUPER XCLUSIVE!",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "Yes",
//     price: 299,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     quantity_and_stock_status: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     usage_category: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     sms_allocation: "",
//     calls_allocation: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_subscribe: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
//   {
//     entity_id: "5833",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "GoEXTRA90-mainstream-7571-GHP-Prepaid",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2023-03-17 01:11:08",
//     updated_at: "2023-05-15 13:50:32",
//     row_id: "5834",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "8GB data for all sites + Unli texts to all networks + Unli calls to Globe/TM + 1GB GoWIFI Access. Valid for 7 days",
//     meta_keyword: "GoEXTRA90",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash, CTL",
//     usage_category: "Data Promo",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "7571",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_id: "9506",
//         charging: "1",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "90",
//         charging: "1",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "DEFAULT",
//         charging: "0",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [
//       {
//         record_id: "0",
//         nf_recurr_id: "10613",
//         charging: "1",
//         nf_recurr_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_recurr_id: "10614",
//         charging: "0",
//         nf_recurr_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_recurr_param: [
//       {
//         record_id: "0",
//         nf_recurr_param: "90",
//         charging: "1",
//         nf_recurr_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_recurr_param: "90",
//         charging: "0",
//         nf_recurr_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     prepaid_promo_freebies: [],
//     name: "GoEXTRA90",
//     meta_title: "GoEXTRA90",
//     meta_description: "GoEXTRA90 ",
//     page_layout: "Product -- Full Width",
//     options_container: "Block after Info Column",
//     url_key: "goextra90",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "Use config",
//     is_returnable: "",
//     tag_color: "4899D4",
//     validity: "7",
//     sms_allocation: "Unli Allnet Texts",
//     calls_allocation: "Unli calls to Globe/TM",
//     skellig_keyword: "PR_PREGS8GB7DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "8GB",
//     skellig_morocco_keyword: "PR_GSGOEX90RENEW8GB7DDVB",
//     skellig_morocco_wallet: "PR_GOSURF_DVB",
//     skellig_morocco_category: "Mobile Data",
//     additional_app_perks: "1GB GoWIFI Access",
//     rank_sku: "9",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     tax_class_id: "Taxable Goods",
//     is_featured: "No",
//     recommended: "Yes",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     freebie_selection_type: "N/A",
//     is_booster_allowed: "No",
//     is_limited: "No",
//     promo_category: "App Exclusives",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "No",
//     price: 90,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     quantity_and_stock_status: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     total_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_subscribe: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks_inner: "",
//     featured_category: "",
//   },
//   {
//     entity_id: "4475",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "GoEXTRA129-mainstream-10806-GHP-Prepaid|GHP MyFi",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2022-10-19 16:13:59",
//     updated_at: "2023-05-15 13:50:31",
//     row_id: "4476",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "8GB data for all sites +  Unli texts to all networks + Unli calls to all networks. Valid for 10 days",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash, CTL",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "10806",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_id: "9920",
//         charging: "1",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "129",
//         charging: "1",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "129",
//         charging: "0",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_name: "",
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "GoEXTRA129",
//     options_container: "Block after Info Column",
//     url_key: "goextra129-mainstream-10806-ghp-prepaid-ghp-myfi",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "No",
//     is_returnable: "",
//     validity: "10",
//     sms_allocation: "Unli Allnet Texts",
//     calls_allocation: "Unli Allnet Calls",
//     skellig_keyword: "PR_PREGOSURF8GB10DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "8GB",
//     featured_category: "Our all-time favorite promos",
//     rank_sku: "13",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-SuperXclusive%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     quantity_and_stock_status: "In Stock",
//     tax_class_id: "Taxable Goods",
//     is_featured: "No",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     is_booster_allowed: "Yes",
//     is_limited: "No",
//     promo_category: "App Exclusives",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "No",
//     price: 129,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     meta_title: "",
//     meta_keyword: "",
//     meta_description: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     page_layout: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     usage_category: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     freebie_selection_type: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     total_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_subscribe: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
//   {
//     entity_id: "4010",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "GoUNLI350-mainstream-9750-GHP-Prepaid",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2022-10-19 16:11:39",
//     updated_at: "2023-05-15 13:50:33",
//     row_id: "4011",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "Unli calls + texts to all networks + 3GB data. Valid for 30 days.",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "GCash, CTL",
//     usage_category: "Data Promo",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "9750",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_id: "9749",
//         charging: "1",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "350",
//         charging: "1",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "DEFAULT",
//         charging: "0",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "GoUNLI350",
//     options_container: "Block after Info Column",
//     url_key: "gounli350-mainstream-9750-ghp-prepaid",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "No",
//     is_returnable: "",
//     validity: "30",
//     sms_allocation: "Unli Allnet Texts",
//     calls_allocation: "Unli Allnet Calls",
//     keyword_subscribe: "GOUNLI350",
//     keyword_stop: "GOUNLI350 STOP",
//     skellig_keyword: "PR_PREGS3GB30DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "3GB",
//     featured_category: "Our all-time favorite promos",
//     rank_sku: "43",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-Mainstream%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-Mainstream%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     quantity_and_stock_status: "In Stock",
//     tax_class_id: "Taxable Goods",
//     is_featured: "No",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     is_booster_allowed: "No",
//     is_limited: "No",
//     promo_category: "All-Time Favorites",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "No",
//     price: 350,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     meta_title: "",
//     meta_keyword: "",
//     meta_description: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     page_layout: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     freebie_selection_type: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     total_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
//   {
//     entity_id: "3975",
//     attribute_set_id: "27",
//     type_id: "virtual",
//     sku: "Go50-mainstream-7964-GHP-Prepaid",
//     has_options: "0",
//     required_options: "0",
//     created_at: "2022-10-19 16:11:36",
//     updated_at: "2023-05-15 13:50:24",
//     row_id: "3976",
//     created_in: "1",
//     updated_in: "2147483647",
//     status: "Enabled",
//     visibility: "Catalog, Search",
//     promo_plan_segment: "Prepaid Mobile Promo",
//     is_visible: "Yes",
//     short_description:
//       "5GB data for all sites + Unli texts to all networks + 1GB GoWiFi. Valid for 3 days",
//     api_subscribe: "ServiceProvision",
//     api_unsubscribe: "ServiceDeprovision",
//     payment_option: "CC/DC, GCash, CTL",
//     usage_category: "Data Promo",
//     nf_main: [
//       {
//         record_id: "0",
//         nf_service_id: "7903",
//         charging: "1",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//       {
//         record_id: "1",
//         nf_service_id: "7964",
//         charging: "0",
//         nf_main_freebie_freebie_type: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_main_param: [
//       {
//         record_id: "0",
//         nf_service_param: "50",
//         charging: "1",
//         initialize: "1",
//         nf_main_param_freebie_freebie: "DEFAULT",
//       },
//       {
//         record_id: "1",
//         nf_service_param: "DEFAULT",
//         charging: "0",
//         nf_main_param_freebie_freebie: "DEFAULT",
//         initialize: "1",
//       },
//     ],
//     nf_recurr: [],
//     nf_recurr_param: [],
//     prepaid_promo_freebies: [
//       {
//         record_id: "0",
//         freebie_category: "",
//         freebie_type: "",
//         freebie_param: "",
//         freebie_description: "",
//         freebie_allocation: "",
//         freebie_validity: "",
//         freebie_sequence: "",
//         initialize: "1",
//       },
//     ],
//     name: "Go50",
//     options_container: "Block after Info Column",
//     url_key: "go50-mainstream-7964-ghp-prepaid",
//     msrp_display_actual_price_type: "Use config",
//     gift_message_available: "No",
//     is_returnable: "",
//     validity: "3",
//     sms_allocation: "Unli Allnet Texts",
//     keyword_subscribe: "GO50",
//     skellig_keyword: "PR_PREGS5GB3DDVB",
//     skellig_wallet: "PR_GOSURF_DVB",
//     skellig_category: "Mobile Data",
//     customer_subtype: "Consumer",
//     mobile_allocation: "5GB",
//     featured_category: "Our all-time favorite promos",
//     rank_sku: "26",
//     catalog_card_background:
//       "https://new.globe.com.ph/assets/files/media/GP-Mainstream%404x.png",
//     promo_details_background:
//       "https://new.globe.com.ph/assets/files/media/GP-Mainstream%20-%20Long%404x.png",
//     platforms_allowed: "Android, IOS, Huawei",
//     quantity_and_stock_status: "In Stock",
//     tax_class_id: "Taxable Goods",
//     is_featured: "No",
//     recommended: "No",
//     main_account_type: "GHP-Prepaid",
//     brand_segment: "MOBILE",
//     is_booster_allowed: "Yes",
//     is_limited: "No",
//     promo_category: "Go",
//     prepaid_promo_type: "Mainstream",
//     is_new_offer: "No",
//     is_popular_search: "No",
//     is_prepurchaseable: "No",
//     is_roamrecommended: "No",
//     forPreProdOnly: "No",
//     is_exclusive: "No",
//     price: 50,
//     weight: 0,
//     store_id: 1,
//     three_d: "",
//     portrait: "",
//     old_id: "",
//     sku_type: "",
//     giftcard_type: "",
//     price_type: "",
//     giftcard_amounts: "",
//     url_path: "",
//     allow_open_amount: "",
//     open_amount_min: "",
//     open_amount_max: "",
//     image_label: "",
//     weight_type: "",
//     small_image_label: "",
//     thumbnail_label: "",
//     category_ids: "",
//     news_from_date: "",
//     news_to_date: "",
//     country_of_manufacture: "",
//     links_purchased_separately: "",
//     samples_title: "",
//     links_title: "",
//     links_exist: "",
//     related_tgtr_position_limit: "",
//     related_tgtr_position_behavior: "",
//     upsell_tgtr_position_limit: "",
//     upsell_tgtr_position_behavior: "",
//     description: "",
//     shipment_type: "",
//     image: "",
//     small_image: "",
//     thumbnail: "",
//     swatch_image: "",
//     media_gallery: "",
//     gallery: "",
//     globe_url_key: "",
//     meta_title: "",
//     meta_keyword: "",
//     meta_description: "",
//     special_price: "",
//     special_from_date: "",
//     special_to_date: "",
//     cost: "",
//     tier_price: "",
//     price_view: "",
//     msrp: "",
//     minimal_price: "",
//     is_redeemable: "",
//     use_config_is_redeemable: "",
//     lifetime: "",
//     use_config_lifetime: "",
//     email_template: "",
//     use_config_email_template: "",
//     allow_message: "",
//     use_config_allow_message: "",
//     page_layout: "",
//     custom_layout_update_file: "",
//     custom_layout_update: "",
//     custom_design_from: "",
//     custom_design_to: "",
//     custom_design: "",
//     custom_layout: "",
//     gift_wrapping_available: "",
//     gift_wrapping_price: "",
//     country_list: "",
//     telco_partners: "",
//     sal_sap_keyword: "",
//     service_fee: "",
//     promo_start_datetime: "",
//     promo_end_datetime: "",
//     link_terms_condition: "",
//     inclusion: "",
//     inclusion_icon: "",
//     offer_icon: "",
//     buy_filter_type: "",
//     buy_filter_budget: "",
//     prepurchase_sub_category: "",
//     freebie_selection_type: "",
//     calls_allocation: "",
//     data_allocation: "",
//     app_data_allocation: "",
//     total_data_allocation: "",
//     data_validity: "",
//     app_name: "",
//     oms_keyword: "",
//     keyword_stop: "",
//     recurr_keyword_stop: "",
//     app_data_title: "",
//     additional_app_perks: "",
//     additional_app_perks_inner: "",
//     tag_color: "",
//     skellig_morocco_keyword: "",
//     skellig_morocco_wallet: "",
//     skellig_morocco_category: "",
//   },
// ];
