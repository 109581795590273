import React from "react";

const ErrorTag = ({ display, formError, isCxsError, isExpired }) => {
   return (
      <span className={`${display} my-3 text-sm font-normal text-error-dim`}>
         {formError || isExpired ? "Please enter a valid mobile number" : ""}
         {isCxsError && !formError && !isExpired
            ? "We've encountered an issue. Please try again later."
            : ""}
      </span>
   );
};

export default ErrorTag;
