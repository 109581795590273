//How to use:
//1. Import this component
//   import ProgressBar from '../components/ProgressBar';
//2. then call the component by
//   <ProgressBar data={[50]} />
//   *data is where you set the width of the bar ex: 0, 50, 75, 100
// Author: byblas@globe.com.ph

import React from "react";

function ProgressBar(props) {
   const value = props.data;
   return (
      <div className="m-0 h-1 w-full bg-highlight">
         <div
            className="h-1 bg-primary"
            style={{
               width: `${value}%`,
            }}
         ></div>
      </div>
   );
}

export default ProgressBar;
