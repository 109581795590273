const formatNumber = async ({ tempNumber }) => {
   let mobileNumber;
   if (
      tempNumber.length === 11 ||
      tempNumber.length === 12 ||
      tempNumber.length === 13
   ) {
      if (/^(?:\+(?:[0-9]{12}))$/gm.test(tempNumber)) {
         mobileNumber = tempNumber.slice(1);
      } else if (/^09\d{9}$/.test(tempNumber)) {
         let num = tempNumber.slice(1);
         let areacode = 63;
         let number = areacode + num;
         mobileNumber = number;
      } else {
         mobileNumber = tempNumber;
      }
   } else {
      console.debug("error");
      return false;
   }
   return mobileNumber;
};

const checkAndFormatNumber = (numberToFormat) => {
   // check first for regex of all possible mobile number format
   const r = /^(?:\+639|639|09)\d{9}$/g;
   if (!r.test(numberToFormat)) {
      return "";
   }

   // format +639xxxyyzz -- remove the '+'
   if (/^\+639\d{9}$/g.test(numberToFormat)) {
      return numberToFormat.slice(1);
   }

   // format 09xxxyyzz -- remove the first 0 then prefix the PH area code
   if (/^09\d{9}$/.test(numberToFormat)) {
      return 63 + numberToFormat.slice(1);
   }

   // NOTE: uncomment if we allow 9xxxyyzz format
   // // format 9xxxyyzz -- just add PH area code
   // if (/^9\d{9}$/.test(numberToFormat)) {
   //   return 63 + numberToFormat;
   // }

   return numberToFormat;
};

export { formatNumber, checkAndFormatNumber };
