class CreatePaymentSessionError extends Error {
   constructor(message) {
      super(message);
      this.name = "CreatePaymentSessionError";
   }
}

class GetPaymentSessionError extends Error {
   constructor(message) {
      super(message);
      this.name = "GetPaymentSessionError";
   }
}

export { CreatePaymentSessionError, GetPaymentSessionError };
