import {
   getAuthToken,
   cacheAuthToken,
   getHTTPSecurityHeaders,
} from "../services/cxs";

/**
 * @async
 * @throws {NetworkError}
 * @returns {Promise<boolean>}
 */
async function getCMSIsMaintenance(queryClient, cmsAppVersion, mobileType) {
   const cmsResp = await queryClient.fetchQuery({
      staleTime: 60000 * process.env.REACT_APP_STALE_TIME_MINS_IS_MAINTENANCE, // [60000 milliseconds == 1 min]
      cacheTime: 60000 * process.env.REACT_APP_STALE_TIME_MINS_IS_MAINTENANCE,
      queryKey: ["cmsMobileIsMaintenance", cmsAppVersion, mobileType],
      queryFn: () => fetchCmsMobileRoutes(cmsAppVersion, mobileType),
   });

   // just a bunch of checking but ultimately the criteria is:
   //    jsonData.data.field_maintenance_page.data is not NULL && jsonData.data.field_maintenance_page.type ==='globe_app_component--maintenance'
   // TODO: confirm if jsonData.data.field_maintenance_page.data should really be NOT null
   const isMaintenance =
      cmsResp &&
      cmsResp.result &&
      cmsResp.result.length === 2 &&
      cmsResp.result[0].Result &&
      cmsResp.result[0].Result.data &&
      cmsResp.result[0].Result.data.field_maintenance_page &&
      // this is non existent whenever MP is up
      cmsResp.result[0].Result.data.field_maintenance_page.data === undefined &&
      cmsResp.result[0].Result.data.field_maintenance_page.type ===
         "globe_app_component--maintenance" &&
      cmsResp.result[1].Result &&
      "isBcpOn" in cmsResp.result[1].Result &&
      cmsResp.result[1].Result.isBcpOn;

   return isMaintenance;
}

export default async function cmsMaintenanceLoader(request, queryClient) {
   const url = new URL(request.url);
   const cmsAppVersion = url.searchParams.get("appVersion");
   const mobileType = url.searchParams.get("mobileType");

   if (cmsAppVersion == null || mobileType == null) {
      return { isMaintenance: false };
   }

   try {
      const isMaintenance = await getCMSIsMaintenance(
         queryClient,
         cmsAppVersion,
         mobileType
      );
      return { isMaintenance: isMaintenance };
   } catch (error) {
      console.debug("cmsMaintenanceLoader -- error");
      console.debug(error);
      return { isMaintenance: false };
   }
}

/**
 * This function will fetch the cms/mobileRoutes
 * which returns the maintainance configuration from
 * Drupal CMS.
 *
 * @param {string} cmsAppVersion - query string param
 * @param {string} mobileType - query string param android|ios
 * @returns {Object} response - json object
 */
function fetchCmsMobileRoutes(cmsAppVersion, mobileType) {
   console.log("Fetching CMSMobileRoutes...");
   const accessToken = getAuthToken();
   const hasAccessToken = !!accessToken;

   const headers = {
      "Content-Type": "application/json",
      ...getHTTPSecurityHeaders(),
   };
   // Attach the accessToken if valid/available from cache.
   if (hasAccessToken) {
      headers.Authorization = "Bearer " + accessToken;
   }
   return fetch(
      `${process.env.REACT_APP_NG1_BCP_SERVICE_BASE_URL}/v1/performanceManagement/cms/mobileRoutes` +
         "?" +
         new URLSearchParams({
            AllRoute: "N",
            CMSRouteDetail: JSON.stringify([
               {
                  mobileRouteId: "maintenance_pages",
                  mobileType: mobileType,
                  version: cmsAppVersion,
               },
               {
                  mobileRouteId: "superapp_maintenance",
                  mobileType: mobileType,
                  version: cmsAppVersion,
               },
            ]),
         }).toString(),
      {
         method: "GET",
         headers: headers,
      }
   ).then((response) => {
      if (
         response.headers.get("Auth-ExpiresIn") &&
         response.headers.get("Authorization") &&
         !hasAccessToken
      ) {
         // If the current available accessToken expires,
         // save new one from response header
         cacheAuthToken(
            response.headers.get("Authorization"),
            response.headers.get("Auth-ExpiresIn")
         );
      }
      return response.json();
   });
}