import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getAuthToken, getHTTPSecurityHeaders } from "../services/cxs";
import { checkAndFormatNumber } from "../utils/formatNumber";

const useBrandedMobileNumber = (msisdn) => {
   const [mobileNumber, setMobileNumber] = useState(
      checkAndFormatNumber(msisdn)
   );

   let mobileNumberParam = "0" + mobileNumber.substring(2);
   const uQ = useQuery({
      enabled: !!mobileNumber,
      staleTime: 60000 * process.env.REACT_APP_STALE_TIME_MINS_MOBILE_BRAND, // [60000 milliseconds == 1 min]
      cacheTime: 60000 * process.env.REACT_APP_STALE_TIME_MINS_MOBILE_BRAND,
      queryKey: ["brandedMobileNumber", mobileNumber],
      queryFn: async () =>
         fetch(
            `${process.env.REACT_APP_NG1_BCP_SERVICE_BASE_URL}/v1/accountManagement/accounts/brand?` +
               new URLSearchParams({ msisdn: mobileNumberParam }).toString(),
            {
               method: "GET",
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + getAuthToken(),
                  ...getHTTPSecurityHeaders(),
               },
            }
         ).then((response) => response.json()),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
   });

   return {
      mobileNumber,
      setMobileNumber,
      isPrepaid: uQ.data ? uQ.data?.result?.brandType === "PREPAID" && uQ.data?.result?.brand === "GHP-Prepaid": false,
      isPostpaid: uQ.data ? uQ.data?.result?.brandType === "POSTPAID" : false,
      isExpired: uQ.error && uQ.data?.message === "Resource Not Found",
      isCxsError: uQ.error || uQ.data?.message,
      ...uQ,
   };
};

export default useBrandedMobileNumber;
