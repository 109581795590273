import grab_logo from "../assets/booster_apps/grab.svg";
import waze_logo from "../assets/booster_apps/waze.svg";
import maps_logo from "../assets/booster_apps/gmaps.svg";
import zoom_logo from "../assets/booster_apps/zoom.svg";
import fbmeetingroom_logo from "../assets/booster_apps/messenger.svg";
import yahoomail_logo from "../assets/booster_apps/yahoomail.svg";
import msteams_logo from "../assets/booster_apps/msteams.svg";
import whatsapp_logo from "../assets/booster_apps/whatsapp.svg";
import viber_logo from "../assets/booster_apps/viber.svg";
import telegram_logo from "../assets/booster_apps/telegram.svg";
import vlive_logo from "../assets/booster_apps/vlive.svg";
import viu_logo from "../assets/booster_apps/viu.svg";
import viki_logo from "../assets/booster_apps/viki.svg";
import youtube_logo from "../assets/booster_apps/youtube.svg";
import netflix_logo from "../assets/booster_apps/netflix.svg";
import wetv_logo from "../assets/booster_apps/wetv.svg";
import shopee_logo from "../assets/booster_apps/shopee.svg";
import lazada_logo from "../assets/booster_apps/lazada.svg";
import facebook_logo from "../assets/booster_apps/facebook.svg";
import instagram_logo from "../assets/booster_apps/instagram.svg";
import carousell_logo from "../assets/booster_apps/carousell.svg";
import kumu_logo from "../assets/booster_apps/kumu.svg";
import iwanttfc_logo from "../assets/booster_apps/iwanttfc.svg";
import vivamax_logo from "../assets/booster_apps/vivamax.svg";
import iqiyi_logo from "../assets/booster_apps/iqiyi.svg";
import disneyplus_logo from "../assets/booster_apps/disneyplus.svg";
import hbo_go_logo from "../assets/booster_apps/hbogo.svg";
import nba_logo from "../assets/booster_apps/nba.svg";
import mobile_legends_logo from "../assets/booster_apps/mobilelegends.svg";
import coc_logo from "../assets/booster_apps/clashofclans.svg";
import pubg_logo from "../assets/booster_apps/pubg.svg";
import codm_logo from "../assets/booster_apps/codm.svg";
import mu2origin_logo from "../assets/booster_apps/mu2origin.svg";
import lolwildrift_logo from "../assets/booster_apps/wildrift.svg";
import legends_runeterra from "../assets/booster_apps/legendsruneterra.svg";
import twitch_logo from "../assets/booster_apps/twitch.svg";
import tiktok_logo from "../assets/booster_apps/tiktok.svg";
import twitter_logo from "../assets/booster_apps/twitter.svg";
import snapchat_logo from "../assets/booster_apps/snapchat.svg";
import houseparty_logo from "../assets/booster_apps/houseparty.svg";
import youtubelearning_logo from "../assets/booster_apps/ytlearning.svg";
import udemy_logo from "../assets/booster_apps/udemy.svg";
import gsuite_logo from "../assets/booster_apps/gsuite.svg";
import edukasyon_logo from "../assets/booster_apps/edukasyon.svg";
import canva_logo from "../assets/booster_apps/canva.svg";
import zalora_logo from "../assets/booster_apps/zalora.svg";
import beautymnl_logo from "../assets/booster_apps/beautymnl.svg";
import grabfood_logo from "../assets/booster_apps/grabfood.svg";
import foodpanda_logo from "../assets/booster_apps/foodpanda.svg";
import ytmusic_logo from "../assets/booster_apps/ytmusic.svg";
import spotify_logo from "../assets/booster_apps/spotify.svg";
import wesing_logo from "../assets/booster_apps/wesing.svg";
import parlon_logo from "../assets/lifestylefreebies_apps/parlon.svg";
import klook_logo from "../assets/lifestylefreebies_apps/klook.svg";
import airasia_logo from "../assets/lifestylefreebies_apps/airasia.svg";
import pickaroo_logo from "../assets/lifestylefreebies_apps/pickaroo.svg";
import genshinimapact_logo from "../assets/booster_apps/genshinimpact.svg";
import pokemonunite_logo from "../assets/booster_apps/pokemonunite.svg";

const BoosterAppIcons = {
   Grab: grab_logo,
   "Google Maps": maps_logo,
   Waze: waze_logo,
   Zoom: zoom_logo,
   "FB Meeting Room": fbmeetingroom_logo,
   "Yahoo! Mail": yahoomail_logo,
   "MS Teams": msteams_logo,
   WhatsApp: whatsapp_logo,
   Viber: viber_logo,
   Telegram: telegram_logo,
   VLive: vlive_logo,
   Viu: viu_logo,
   Viki: viki_logo,
   YouTube: youtube_logo,
   Netflix: netflix_logo,
   WeTV: wetv_logo,
   Shopee: shopee_logo,
   Lazada: lazada_logo,
   Facebook: facebook_logo,
   Instagram: instagram_logo,
   Carousell: carousell_logo,
   Kumu: kumu_logo,
   iWantTFC: iwanttfc_logo,
   Vivamax: vivamax_logo,
   iQiyi: iqiyi_logo,
   "Disney+": disneyplus_logo,
   "HBO Go": hbo_go_logo,
   NBA: nba_logo,
   "Mobile Legends": mobile_legends_logo,
   ClashofClans: coc_logo,
   PUBG: pubg_logo,
   "Call of Duty": codm_logo,
   "MU Origin 2": mu2origin_logo,
   "Wild Rift": lolwildrift_logo,
   "Legends of Runeterra": legends_runeterra,
   Twitch: twitch_logo,
   TikTok: tiktok_logo,
   Twitter: twitter_logo,
   Snapchat: snapchat_logo,
   Houseparty: houseparty_logo,
   "YouTube Learning": youtubelearning_logo,
   Udemy: udemy_logo,
   "Google Suite": gsuite_logo,
   Edukasyon: edukasyon_logo,
   Canva: canva_logo,
   ZALORA: zalora_logo,
   BeautyMNL: beautymnl_logo,
   "Grab Food": grabfood_logo,
   Foodpanda: foodpanda_logo,
   "YouTube Music": ytmusic_logo,
   Spotify: spotify_logo,
   SoundCloud: false,
   WeSing: wesing_logo,
   Parlon: parlon_logo,
   Klook: klook_logo,
   AirAsia: airasia_logo,
   Pickaroo: pickaroo_logo,
   "Genshin Impact": genshinimapact_logo,
   "Pokemon Unite": pokemonunite_logo,
   /* "Discord": discord_logo, */
};

export { BoosterAppIcons };
