/**
 * This file houses all the utility functions that are used to access or
 * sort the Prepaid Object
 */
import { PREPAID_PROMOS_DATA } from "../data/promoData";

const ENTERTAINMENT_FREEBIES_LIST = [
   "GoWATCH",
   "GoPLAY",
   "GoSHARE",
   "GoLEARN",
   "GoWORK",
   "GoLISTEN",
   "GoSHOP",
   "GoRIDE",
   "GoKOREAN",
   "GoPINOY",
   "GoANIME",
   "GoSELL",
];

const LIFESTYLE_FREEBIES_LIST = [
   "Php50 Lazada voucher",
   "Php300 voucher on Parlon Deals",
   "5% off on Klook Activities",
   "Php60 AirAsia voucher on Hotel Bookings",
   "Php200 voucher on Pick.A.Roo Shops",
   "Php50 voucher on GrabCar/Share",
];

/**
 * This utility function extracts the specified promo by the
 * argument id that was passed
 * @param { String } id - The entity id of the promo selected
 * @returns Prepaid Prono object
 */
const getPromoById = (id) => {
   return PREPAID_PROMOS_DATA.find((obj) => {
      return obj.entity_id === id;
   });
};

/**
 * This utility function checks if the nf_main_param
 * only has DEFAULT label in their options
 * @param {string} id - The entity id of the promo selected
 * @returns boolean whether it only has DEFAULTs
 */
const checkForDefaultExtras = (id) => {
   const prepaidPromo = getPromoById(id);

   const defaultNfMains = prepaidPromo.nf_main_param.filter(
      (obj) => obj.nf_main_param_freebie_freebie !== "DEFAULT"
   );

   // Only has defautl extras
   if (defaultNfMains.length === 0) return true;

   return false;
};

/**
 * This utility function is used to extract the default charging service id
 * for objects that are selected
 * @param { String } id - The entity id of the promo selected
 * @returns
 */
const getNfMainServiceId = (id) => {
   const prepaidPromo = getPromoById(id);

   let selectedNfMain = prepaidPromo.nf_main.find((obj) => {
      // If not return, return the item that matches the text
      return obj.charging === "0";
   });

   return { selectedNfMain };
};

/**
 * This utility function is used to extract the nf_main param of the promo
 * and combination of freebies selected
 * @param { String } entertainment - The entertainment freebie name
 * @param { String } lifestyle - The lifestyle freebie name
 * @param { String } id - The entity id of the promo selected
 * @returns
 */
const getNfMainParam = ({ entertainment, lifestyle, id }) => {
   const prepaidPromo = getPromoById(id);
   let matchText = "DEFAULT";

   if (entertainment && lifestyle) {
      matchText = `${entertainment.trim()}|${lifestyle.trim()}`;
   } else if (entertainment) {
      matchText = `${entertainment.trim()}`;
   } else if (lifestyle) {
      matchText = `${lifestyle.trim()}`;
   }

   let selectedNfMainParam = prepaidPromo.nf_main_param.find((obj) => {
      // Expected at least 2 objects, return the item that is charging
      if (obj.nf_main_param_freebie_freebie === "DEFAULT")
         return obj.charging === "0";

      // If not return, return the item that matches the text
      return obj.nf_main_param_freebie_freebie === matchText;
   });

   return { selectedNfMainParam };
};

/**
 * This utility function is used to sort the freebies
 * to its appropriate category
 * @param { String } id - The entity id of the promo selected
 * @returns
 */
const sortFreebiesById = (id) => {
   let numberOfRequiredFreebies = 0;

   const prepaidPromo = getPromoById(id);

   const entertainmentFreebies = prepaidPromo.prepaid_promo_freebies.filter(
      (obj) => ENTERTAINMENT_FREEBIES_LIST.includes(obj.freebie_name)
   );

   const lifestyleFreebies = prepaidPromo.prepaid_promo_freebies.filter((obj) =>
      LIFESTYLE_FREEBIES_LIST.includes(obj.freebie_name)
   );

   if (entertainmentFreebies.length > 0) numberOfRequiredFreebies++;
   if (lifestyleFreebies.length > 0) numberOfRequiredFreebies++;

   return {
      entertainmentFreebies,
      lifestyleFreebies,
      numberOfRequiredFreebies,
   };
};

/**
 * This utility function extracts the app names of from the freebie apps
 * array of JSON strings from display promo catalog data.
 * @param {Object} - Has apps key in object
 * @returns
 */
const getBoosterApps = ({ apps }) => {
   const appNames = apps.map((app) => {
      const freebieObj = JSON.parse(app);
      return freebieObj.label;
   });

   // Removes duplicates
   return [...new Set(appNames)];
};

export {
   checkForDefaultExtras,
   getBoosterApps,
   getNfMainParam,
   getNfMainServiceId,
   getPromoById,
   sortFreebiesById,
};
