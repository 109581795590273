export function getCache(key) {
   const base64toKey = window.btoa(key);
   const encodedValue = localStorage.getItem(base64toKey);
   if (encodedValue) {
      return JSON.parse(window.atob(encodedValue));
   }
   return null;
}

export function setCache(key, value) {
   const keyToBase64 = window.btoa(key);
   const valueToBase64 = window.btoa(JSON.stringify(value));
   localStorage.setItem(keyToBase64, valueToBase64);
}

export function removeCache(key) {
   const base64toKey = window.btoa(key);
   localStorage.removeItem(base64toKey);
}
