import React from "react";

function carouselCatalog(props) {
   let path = props.path;
   let isSelected = props.isSelected;
   let name = props.name;

   return (
      <div className="mx-1 mb-3 mt-4 flex hidden flex-col justify-start drop-shadow-md">
         <div className="max-h-xl flex max-w-xl flex-row space-x-4 overflow-x-scroll  hover:overflow-x-scroll [&::-webkit-scrollbar]:hidden">
            <div
               className={`{ h-9 w-auto flex-none rounded-lg border-2 border-highlight bg-light-highlight p-chips-v2 text-center font-semibold hover:border
          hover:border-primary ${
             isSelected
                ? "border-primary bg-primary text-absolute-white"
                : "border-highlight text-accent-dark "
          }`}
            >
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  Just For You{" "}
               </a>
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  {name}{" "}
               </a>
            </div>
            <div
               className={`{ h-9 w-auto flex-none rounded-lg border-2 border-highlight bg-highlight p-chips-v2 text-center font-semibold hover:border
          hover:border-primary ${
             true
                ? "border-primary bg-primary text-absolute-white"
                : "border-highlight text-accent-dark "
          }`}
            >
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  Globe One Exclusives{" "}
               </a>
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  {name}{" "}
               </a>
            </div>
            <div
               className={`{ h-9 w-auto flex-none rounded-lg border-2 border-highlight bg-light-highlight p-chips-v2 text-center font-semibold hover:border
          hover:border-primary ${
             isSelected
                ? "border-primary bg-primary text-absolute-white"
                : "border-highlight text-accent-dark "
          }`}
            >
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  Our All-time Faves{" "}
               </a>
               <a href={path} className="cursor-pointer text-sm">
                  {" "}
                  {name}{" "}
               </a>
            </div>
         </div>
      </div>
   );
}

export default carouselCatalog;
