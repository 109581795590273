import React from "react";

const BrandTag = ({ text }) => {
   return (
      <div className="absolute inset-y-0 left-0 ml-36 flex items-center">
         <span className="absolute top-3.5 whitespace-nowrap rounded bg-neutral-b2 px-2 text-xs font-light text-neutral-a2">
            {text}
         </span>
      </div>
   );
};

export default BrandTag;
