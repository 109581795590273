import React from "react";
import MaintenanceSVG from "../assets/maintenance-logo.svg";

const MaintenanceNotice = ({ isMaintenance = false }) => {
   const notice = "The page you're looking for doesn't exist.";

   return (
      <div className="space-y-8 px-7 py-6 text-center">
         <img
            className="mx-auto w-60"
            src={MaintenanceSVG}
            alt="Maintenance logo"
         />
         <p className="text-xl font-bold">404</p>

         <p className="text-xl font-bold">
            <span className="text-red-500">Oops!</span> Page not found
         </p>

         <p className="text-lg text-neutral-a1">{notice}</p>
      </div>
   );
};

function NotFound() {
   return (
      <div className="min-w-xs max-w-xl">
         <MaintenanceNotice isMaintenance={true}></MaintenanceNotice>
      </div>
   );
}

export default NotFound;
