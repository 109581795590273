/**
 * @author seancabalse
 * @email seancabalse.dev@gmail.com
 * @create date 2023-07-19 11:31:13
 * @modify date 2023-07-19 11:31:13
 * @desc This file houses the code and logic for the Boosters page of
 * this application.
 */

import React from "react";
import ChevronLeftSVG from "../assets/chevron-left.svg";
import ErrorSVG from "../assets/error.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PREPAID_BOOSTERS_DATA } from "../data/promoData";
import { BoosterAppIcons } from "../utils/boosterApp";
import { useBoosters } from "../providers/Booster";

const ToastNotification = () => {
   const { setIsMoreThanThree } = useBoosters();
   return (
      <div
         role="status"
         className="absolute inset-x-0 bottom-40 z-50 flex h-full min-h-max min-w-xs items-end justify-center px-5 transition delay-200 ease-in-out"
         onClick={() => setIsMoreThanThree(false)}
      >
         <div className="mb-10 flex min-w-xs max-w-md flex-row space-y-4 rounded-md border-t bg-corporate-blue px-6  py-3 shadow-2xl">
            <div className="flex items-center justify-center text-center">
               <img className="h-10 w-10" src={ErrorSVG} alt="Error icon" />
            </div>
            <div className="py-3 text-center">
               <p className="px-4 text-sm font-bold text-absolute-white">
                  You can only select a maiximum of three (3) boosters.
               </p>
            </div>
         </div>
      </div>
   );
};

/**
 * This component houses the code and logic for the header of the Boosters
 * page
 * @returns JSX object containing the header of the Boosters page.
 */
const TopSection = () => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const mobileNumber = useParams().mobileNumber;
   const { setSelectedBoosters } = useBoosters();

   const backButtonIsClicked = () => {
      setSelectedBoosters([]);
      navigate({
         pathname: `/${mobileNumber}/prepaid`,
         search: searchParams.toString(),
      });
   };

   return (
      <div className="z-10 w-full max-w-xl bg-accent-light px-4 py-3 shadow-lg">
         <div className="flex items-center justify-between py-2.5">
            <img
               src={ChevronLeftSVG}
               onClick={() => backButtonIsClicked()}
               className="cursor-pointer"
               alt="Go Back"
            />

            <p className="font-bold uppercase tracking-widest text-accent-dark">
               Add Boosters
            </p>

            <div></div>
         </div>
      </div>
   );
};

/**
 * This functional component is responsible for rendering the booster cards
 * @param {*} param0
 * @returns - JSX object containing the booster details in card forms
 */
const BoosterCatalogPanel = ({
   promoName,
   promoPrice,
   promoDescription,
   appNames,
   serviceId,
   nfParam,
   entityId,
}) => {
   const apps = appNames.split("|");
   const { selectedBoosters, setSelectedBoosters, setIsMoreThanThree } =
      useBoosters();

   // Handles the click if booster is selected
   const handleClick = () => {
      // Remove booster if selected and clicked
      if (
         selectedBoosters.filter((record) => record.serviceId === serviceId)
            .length > 0
      ) {
         setSelectedBoosters(
            selectedBoosters.filter((record) => record.serviceId !== serviceId)
         );
         return;
      }

      // Check if more than 3 boosters are selected
      if (selectedBoosters.length > 2) {
         setIsMoreThanThree(true);
      } else {
         const boosterTransactionData = {
            amount: promoPrice,
            serviceId: serviceId,
            param: nfParam,
            name: promoName,
         };
         setSelectedBoosters((prevList) => [
            ...prevList,
            boosterTransactionData,
         ]);
      }
   };

   const isBoosterSelected = () => {
      return (
         selectedBoosters.filter((record) => record.serviceId === serviceId)
            .length > 0
      );
   };

   return (
      <div
         data-testid={`booster-panel-${promoName}`}
         className={`group space-y-4 rounded-lg px-4 pb-5 pt-3 shadow-lg hover:border-primary hover:bg-light-blue 
      ${
         isBoosterSelected()
            ? "border border-primary bg-light-blue"
            : "bg-white"
      }  
    `}
         onClick={() => handleClick()}
      >
         <div
            className={`flex items-center justify-between font-bold group-hover:text-primary ${
               isBoosterSelected() ? "text-primary" : "text-neutral-a0"
            }`}
         >
            <p data-testid={`booster-name-${promoName}`}>{promoName}</p>
            <p data-testid={`booster-price-${promoName}`}>+₱{promoPrice}</p>
         </div>
         <div className="flex gap-1">
            <p data-testid={`booster-desc-${promoName}`} className="text-xs">
               {promoDescription}
            </p>
         </div>
         <div className="flex gap-1">
            {apps
               .filter(
                  (data) => data in BoosterAppIcons && BoosterAppIcons[data]
               )
               .map((data) => (
                  <span key={`${serviceId}-${data}`} className="h-6 w-6">
                     <img
                        src={BoosterAppIcons[data].toString()}
                        alt={`${data}-icon`}
                     />
                  </span>
               ))}
         </div>
      </div>
   );
};

/**
 * This component houses the code and content of the Booster page.
 * page
 * @returns JSX object containing the banner message fo the Boosters page.
 */
const BoosterBanner = () => {
   const categoryText = "Boosters";
   const bannerText = "Do more with your promo by adding boosters!";

   return (
      <div className="space-y-3 bg-transparent px-4 pb-5 pt-3">
         <div className="flex items-center justify-between font-bold text-neutral-a0">
            <p>{categoryText}</p>
            <p>
               <span>1</span>
               <span className="text-neutral-a4">/1</span>
            </p>
         </div>
         <div className="flex flex-col">
            <p className="text-xs">{bannerText}</p>
            <p className="text-xs">Select up to three (3).</p>
         </div>
      </div>
   );
};

/**
 * This functional component renders all the Booster cards for the Booster
 * page.
 * @returns - JSX element of the container with all the Booster cards available.
 */
const DisplayBoosters = () => {
   const getBoosterServiceId = (nfMain) => {
      const chargingNf = nfMain.filter((record) => record.charging === "0");
      return chargingNf[0].nf_service_id;
   };

   const getBoosterNfParam = (nfMainParam) => {
      const chargingNfParam = nfMainParam.filter(
         (record) => record.charging === "0"
      );
      return chargingNfParam[0].nf_service_param;
   };

   return (
      <div
         data-testid={`display-booster-wrapper`}
         className="relative space-y-5 overflow-y-scroll px-4 pb-8 pt-4"
      >
         {PREPAID_BOOSTERS_DATA.map((promo) => (
            <BoosterCatalogPanel
               promoName={promo.name}
               promoPrice={promo.price}
               promoDescription={promo.short_description}
               appNames={promo.booster_app_name}
               serviceId={getBoosterServiceId(promo.nf_main)}
               nfParam={getBoosterNfParam(promo.nf_main_param)}
               key={promo.entity_id}
               entityId={promo.entity_id}
            />
         ))}
      </div>
   );
};

const BottomPanel = () => {
   const { selectedBoosters, setSelectedBoosters } = useBoosters();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const mobileNumber = useParams().mobileNumber;
   let price = "0.00";

   if (selectedBoosters.length > 0) {
      price = selectedBoosters
         .map((record) => record.amount)
         .reduce((a, b) => a + b);
   }

   const clearSelectedBoosterPromos = () => {
      setSelectedBoosters([]);
   };

   return (
      <div className="z-10 flex w-full max-w-xl flex-col rounded-t-2xl border-t bg-white pb-4 shadow-2xl">
         <div className="space-y-4 px-6 py-4">
            <div className="flex items-center justify-between font-bold">
               <p className="text-xs text-neutral-a2">Booster total</p>
               <p
                  className="text-lg text-neutral-a0"
                  data-testid="booster-total"
               >
                  ₱{price}
               </p>
            </div>
            <button
               className={`w-full rounded-lg bg-primary py-2 font-bold text-white`}
               onClick={() =>
                  navigate({
                     pathname: `/${mobileNumber}/prepaid`,
                     search: searchParams.toString(),
                  })
               }
            >
               Confirm
            </button>
            <button
               className={`w-full rounded-lg bg-white py-2 font-bold text-primary`}
               onClick={() => clearSelectedBoosterPromos()}
            >
               Clear selection
            </button>
         </div>
      </div>
   );
};

/**
 * This function is responsible for rendering the Boosters page.
 * @returns JSX Elements for the Boosters page.
 */
const BoostersCatalog = () => {
   const { isMoreThanThree } = useBoosters();

   return (
      <div className="pancake-stack hoscreen overscroll-noe max-h-screen min-h-screen max-w-xl">
         {isMoreThanThree && <ToastNotification />}
         <TopSection />
         <BoosterBanner />
         <DisplayBoosters />
         <BottomPanel />
      </div>
   );
};

export default BoostersCatalog;
