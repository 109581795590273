/**
 * @author seancabalse
 * @email seancabalse.dev@gmail.com
 * @create date 2023-07-04 09:03:42
 * @modify date 2023-07-04 09:03:42
 * @desc This houses the code for the maintenance page processes error
 * provider. This is concerned primarily on the determnining if there are
 * any errors when calling the APIs.
 */

import React, { useState, useContext, createContext, useMemo } from "react";

const ErrorContext = createContext();

/**
 * Wrapper method that can be used to wrap the main appliation with an
 * authenticated context
 * @param { children } - React.JSX.Element that will be wrapped.
 * @returns React.JSX.Element that has access to the CXS channel token
 */
export const ErrorProvider = ({ children }) => {
   const [isError, setIsError] = useState(false);
   const [errorData, setErrorData] = useState("");
   const errorMemo = useMemo(
      () => ({ isError, setIsError, errorData, setErrorData }),
      [isError, errorData]
   );

   return (
      <ErrorContext.Provider value={errorMemo}>
         {children}
      </ErrorContext.Provider>
   );
};

/**
 * Helper method to return the react provider context
 * @returns useContext hook using AuthContext
 */
export const useError = () => {
   return useContext(ErrorContext);
};
