import React from "react";
import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
   RouterProvider,
   createBrowserRouter,
   redirect,
} from "react-router-dom";
import Failed from "./pages/Failed";
import FreebieCatalog from "./pages/FreebieCatalog";
import { MaintenancePage } from "./pages/MaintenancePage";
import PromoCatalog from "./pages/PromoCatalog";
import BoostersCatalog from "./pages/BoostersCatalog";
import Success from "./pages/Success";
import NotFound from "./pages/NotFound";
import cmsMaintenanceLoader from "./routeLoaders/cmsMaintenanceLoader";
import { ErrorProvider } from "./providers/Error";
import { BoosterProvider } from "./providers/Booster";

const queryClient = new QueryClient();

const router = createBrowserRouter([
   {
      path: "/",
      element: <MaintenancePage />,
      loader: async ({ request }) => {
         return await cmsMaintenanceLoader(request, queryClient);
      },
   },
   {
      path: "/:mobileNumber/prepaid",
      // element: <PromoCatalog />,
      element: <Success />,
      loader: async ({ request }) => {
         const d = await cmsMaintenanceLoader(request, queryClient);

         return d.isMaintenance ? d : redirect("/");
      },
   },
   {
      path: "/:mobileNumber/prepaid/:entityId/freebie",
      element: <FreebieCatalog />,
   },
   {
      path: "/:mobileNumber/prepaid/:entityId/boosters",
      element: <BoostersCatalog />,
   },
   {
      path: "/success",
      element: <Success />,
      loader: async ({ request }) => {
         const d = await cmsMaintenanceLoader(request, queryClient);

         return d.isMaintenance ? d : redirect("/");
      },
   },
   {
      path: "/failed",
      element: <Failed />,
      loader: async ({ request }) => {
         const d = await cmsMaintenanceLoader(request, queryClient);

         return d.isMaintenance ? d : redirect("/");
      },
   },
   {
      path: "/*",
      element: <NotFound />,
   },
]);

function App() {
   return (
      <div className="mx-auto w-full min-w-xs max-w-xl overscroll-none">
         <QueryClientProvider client={queryClient}>
            <ErrorProvider>
               <BoosterProvider>
                  <RouterProvider router={router} />
               </BoosterProvider>
            </ErrorProvider>
            <ReactQueryDevtools />
         </QueryClientProvider>
      </div>
   );
}

export default App;
