import React, { useState, useEffect } from "react";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import MaintenanceSVG from "../assets/maintenance-logo.svg";
import MobileNumberField from "../components/MobileNumberField";
import WholeScreenLoading from "../components/WholeScreenLoading";
import useBrandedMobileNumber from "../hooks/useBrandedMobileNumber";
import { checkAndFormatNumber } from "../utils/formatNumber";
import { removeCache } from "../services/cache";

const MaintenanceNotice = ({ isMaintenance = false }) => {
   const notice = isMaintenance ? (
      "GlobeOne is currently undergoing maintenance. In the meantime, you can use our buy promo service."
   ) : (
      <>
         <a href="https://new.globe.com.ph" className="underline">
            Go to Globe
         </a>

         {/* <meta httpEquiv="refresh" content="0; url=http://new.globe.com.ph/" /> */}
      </>
   );

   return (
      <div className="space-y-8 px-7 py-6 text-center">
         <img
            className="mx-auto w-60"
            src={MaintenanceSVG}
            alt="Maintenance logo"
         />

         <p className="text-xl font-robotobold text-accent-dark">We'll be back soon!</p>

         <p className="text-lg text-neutral-a1">{notice}</p>
      </div>
   );
};

const MaintenancePageWithFeatures = () => {
   const {
      isFetching,
      mobileNumber,
      setMobileNumber,
      isPrepaid: isGlobePrepaid,
      isCxsError,
      isExpired,
   } = useBrandedMobileNumber("");

   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const [formError, setFormError] = useState();
   const [checkBrandError, setCheckBrandError] = useState(false);

   useEffect(() => {
      setCheckBrandError(isCxsError || isExpired);
   }, [isCxsError, isExpired]);

   return (
      <div className="min-w-xs max-w-xl">
         {isFetching && <WholeScreenLoading></WholeScreenLoading>}

         <MaintenanceNotice isMaintenance={true}></MaintenanceNotice>

         <div className="fixed bottom-0 w-full min-w-xs max-w-xl rounded-t-2xl border-t bg-white px-6 pb-4 shadow-2xl transition-all duration-700 ease-out">
            <p className="border-b border-b-highlight py-4 text-center text-lg font-bold text-accent-dark">
               Buy Promos
            </p>

            <div className="space-y-4 py-4">
               <div className="space-y-2">
                  <label
                     htmlFor="mobile-number"
                     className="text-sm font-bold text-neutral-a0"
                  >
                     Mobile Number
                  </label>
                  <MobileNumberField
                     formError={formError}
                     mobileNumber={mobileNumber}
                     isPrepaid={isGlobePrepaid}
                     isCheckingBrand={isFetching}
                     isCxsError={checkBrandError}
                     isExpired={checkBrandError}
                     onChange={(e) => {
                        const formatResult = checkAndFormatNumber(
                           e.target.value
                        );
                        if (formatResult !== "") {
                           e.target.value = formatResult;
                           setMobileNumber(formatResult);
                           setFormError(false);
                        } else {
                           if (
                              !/^\d+$/.test(e.target.value.trim()) &&
                              e.target.value.trim().length !== 0
                           ) {
                              setFormError(true);
                           } else if (e.target.value.trim().length > 11) {
                              setFormError(true);
                           } else {
                              setFormError(false);
                              setCheckBrandError(false);
                           }
                        }
                     }}
                  ></MobileNumberField>
                  {!isGlobePrepaid &&
                     !formError &&
                     !isCxsError &&
                     !isExpired && (
                        <p className="text-sm text-neutral-a3">
                           Only Globe Prepaid number can buy promos
                        </p>
                     )}
               </div>

               <button
                  className={`w-full rounded-lg py-2 font-bold ${
                     isGlobePrepaid
                        ? "bg-primary text-white"
                        : "disabled bg-neutral-a5 text-neutral-a4"
                  }`}
                  disabled={!isGlobePrepaid}
                  onClick={() =>
                     navigate({
                        pathname: `/${mobileNumber}/prepaid`,
                        search: searchParams.toString(),
                     })
                  }
               >
                  Proceed
               </button>
            </div>
         </div>
      </div>
   );
};

const MaintenancePage = () => {
   const loaderData = useLoaderData();

   useEffect(() => {
      removeCache("entertainment");
      removeCache("lifestyle");
   }, [loaderData]);

   return loaderData.isMaintenance ? (
      <MaintenancePageWithFeatures />
   ) : (
      <div className="min-w-xs max-w-xl">
         <MaintenanceNotice></MaintenanceNotice>
      </div>
   );
};

export { MaintenancePage };
