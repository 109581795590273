import { getCache, setCache } from "./cache";
import { CreatePaymentSessionError, GetPaymentSessionError } from "./error";

export function getHTTPSecurityHeaders() {
   return {
      "Cache-Control": "no-store",
      "Content-Security-Policy": "frame-ancestors 'none'",
      "Strict-Transport-Security": "max-age=7776000; includeSubDomains",
      "X-Content-Type-Options": "nosniff",
      "X-Frame-Options": "DENY",
   };
}

export function getHttpCommonOptWithAccessToken() {
   return {
      headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer " + getAuthToken(),
         ...getHTTPSecurityHeaders(),
      },
   };
}

export async function cxsCreatePaymentSession(
   mobileNumber,
   nfParam,
   price,
   gcashTotalPrice,
   serviceId,
   searchParams,
   boosterTxnArr
) {
   const newparams = new URLSearchParams(searchParams);
   newparams.delete("mobileNumber");
   
   const body = {
      paymentType: "GCASH",
      currency: "PHP",
      countryCode: "PH",
      paymentInformation: {
         notificationUrls: [
            {
               type: "PAY_RETURN",
               url: `${process.env.REACT_APP_DOMAIN_URL}/success?${newparams}&mobileNumber=${mobileNumber}`,
            },
            {
               type: "CANCEL_RETURN",
               url: `${
                  process.env.REACT_APP_DOMAIN_URL
               }/${mobileNumber}/prepaid?${searchParams}&transactionFailed=${true}`,
            },
         ],
         environmentInformation: {
            orderTerminalType: "WEB",
            terminalType: "WEB",
         },
         productCode: "",
         order: { orderTitle: "SA-BPROMO" },
      },
      settlementInformation: [
         {
            emailAddress: "",
            amount: gcashTotalPrice,
            transactionType: "N",
            mobileNumber: mobileNumber,
            requestType: "BuyPromo",
            transactions: [
               {
                  serviceId: serviceId,
                  param: nfParam,
                  amount: price,
               },
               ...boosterTxnArr
            ],
         },
      ],
   };
   

   const response = await fetch(
      `${process.env.REACT_APP_NG1_BCP_SERVICE_BASE_URL}/v1/paymentManagement/payments/sessions`,
      {
         ...getHttpCommonOptWithAccessToken(),
         method: "POST",
         body: JSON.stringify(body),
      }
   )
      .then((response) => response.json())
      .catch((error) => {
         throw new CreatePaymentSessionError(JSON.stringify(error));
      });

   if (!response) {
      throw new CreatePaymentSessionError("Internal Server Error");
   }

   if (response.error) {
      throw new CreatePaymentSessionError(JSON.stringify(response.error));
   }

   setCache("ng1_bcf_token_payload_id", response.result.tokenPaymentId);
   setCache("ng1_bcp_gcash_total_price", gcashTotalPrice);

   return response.result.tokenPaymentId;
}

export async function cxsGetPaymentSessionByTokenPaymentId(paymentSessionId) {
   const response = await fetch(
      `${process.env.REACT_APP_NG1_BCP_SERVICE_BASE_URL}/v1/paymentManagement/payments/${paymentSessionId}/sessions`,
      { ...(await getHttpCommonOptWithAccessToken()), method: "GET" }
   ).then((resp) => resp.json());

   if (response.error) {
      throw new GetPaymentSessionError(JSON.stringify(response.error));
   }

   return response.result;
}

/**
 * This method will retrieve valid cached token
 * from localStorage
 * @returns {string} userToken - Token stored on cache
 */
export function getAuthToken() {
   // Use cache if existing & valid
   let cxsUserToken = getCache("ng1_bcp_mw_auth_token");
   if (
      cxsUserToken &&
      cxsUserToken.userToken &&
      cxsUserToken.expires &&
      // make sure the token has not expired yet
      new Date() < Date.parse(cxsUserToken.expires)
   ) {
      return cxsUserToken.userToken;
   }
}

/**
 * This method will save new token that returned from
 * cmsMobileRoutes response header
 *
 * @param {string} authToken - Authorization token from response header
 * @param {string} expirationInSecs - Auth-ExpiresIn token from response header
 */
export function cacheAuthToken(authToken, expirationInSecs) {
   let expirationDate = new Date();
   // compute date for userToken to expire
   expirationDate.setSeconds(
      expirationDate.getSeconds() + Number(expirationInSecs)
   );

   setCache("ng1_bcp_mw_auth_token", {
      userToken: authToken,
      expires: expirationDate,
   });
}
