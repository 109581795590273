/**
 * @author seancabalse
 * @email seancabalse.dev@gmail.com
 * @create date 2023-07-20 00:57:25
 * @modify date 2023-07-20 00:57:25
 * @desc This file houses the code and logic for the booster loader provider.
 * This is concerned primarily on keeping that state of the boosters selected
 * and loaded.
 */

import React, { useState, useContext, createContext, useMemo } from "react";

const BoosterContext = createContext();

/**
 * Wrapper method that can be used to wrap the main appliation with an
 * authenticated context
 * @param { children } - React.JSX.Element that will be wrapped.
 * @returns React.JSX.Element that has access to the CXS channel token
 */
export const BoosterProvider = ({ children }) => {
   const [selectedBoosters, setSelectedBoosters] = useState([]);
   const [isMoreThanThree, setIsMoreThanThree] = useState(false);
   const boosterMemo = useMemo(
      () => ({
         selectedBoosters,
         setSelectedBoosters,
         isMoreThanThree,
         setIsMoreThanThree,
      }),
      [selectedBoosters, isMoreThanThree]
   );

   return (
      <BoosterContext.Provider value={boosterMemo}>
         {children}
      </BoosterContext.Provider>
   );
};

/**
 * Helper method to return the react provider context
 * @returns useContext hook using AuthContext
 */
export const useBoosters = () => {
   return useContext(BoosterContext);
};
