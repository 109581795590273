import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ChevronLeftSVG from "../assets/chevron-left.svg";
import InfoIcon from "../assets/info-icon.svg";
import ProgressBar from "../components/ProgressBar";
import { BoosterAppIcons } from "../utils/boosterApp";
import { getBoosterApps, sortFreebiesById } from "../utils/promoUtils";
import { removeCache, setCache } from "../services/cache";

/**
 * This utlity function is responsible for loading the top part of the page
 * @returns Top section of the page
 */
const TopSection = ({
   hasEFreebies,
   hasLsFreebies,
   data,
   setData,
   setFreebieCategory,
}) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const mobileNumber = useParams().mobileNumber;

   const backButtonIsClicked = () => {
      if (data === 100 && hasEFreebies && hasLsFreebies) {
         setData(50);
         setFreebieCategory("entertainment");
      } else {
         removeCache("entertainment");
         removeCache("lifestyle");
         navigate({
            pathname: `/${mobileNumber}/prepaid`,
            search: searchParams.toString(),
         });
      }
   };

   return (
      <div
         className="z-10 w-full max-w-xl bg-accent-light px-4 py-3 shadow-lg"
         data-testid="top-section"
      >
         <div className="flex items-center justify-between py-2.5">
            <img
               src={ChevronLeftSVG}
               onClick={() => backButtonIsClicked()}
               className="cursor-pointer"
               alt="Go Back"
            />

            <p className="font-bold uppercase tracking-widest text-accent-dark">
               Select Freebie
            </p>

            <div></div>
         </div>
      </div>
   );
};

/**
 * This utility function is used to load the bottom section of the page
 * @param {*} Object that contains the setter function for the freebie category
 * @returns The bottom sections that contains the button element
 */
const BottomPanel = ({
   fn,
   data,
   hasBoth,
   eFreebie,
   lsFreebie,
   setIsFreebieConfirmed,
}) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const mobileNumber = useParams().mobileNumber;
   /**
    * This utility method is used to change the
    * freebie category, it is done like this instead of boolean to anticipate
    * changes or addition to list of categories.
    */
   const changeFreebieCategory = () => {
      if (data === "entertainment") fn("lifestyle");
      if (data === "lifestyle") fn("entertainment");
   };

   const confirmSelectedFreebies = () => {
      setIsFreebieConfirmed(true);
      navigate({
         pathname: `/${mobileNumber}/prepaid`,
         search: searchParams.toString(),
      });
   };

   return (
      <div
         className="z-10 flex w-full max-w-xl flex-col rounded-t-2xl border-t bg-white pb-4 shadow-2xl"
         data-testid="bottom-panel"
      >
         {hasBoth ? (
            <div className="space-y-4 px-6 py-4">
               {data === "entertainment" ? (
                  <button
                     data-testid="next-button"
                     className={`w-full rounded-lg py-2 font-bold ${
                        eFreebie.length !== 0
                           ? "bg-primary text-white"
                           : "disabled bg-neutral-a5 text-neutral-a4"
                     } `}
                     onClick={() => changeFreebieCategory()}
                     disabled={eFreebie.length === 0 ? true : false}
                  >
                     Next
                  </button>
               ) : (
                  <>
                     <button
                        className={`w-full rounded-lg py-2 font-bold ${
                           lsFreebie.length !== 0
                              ? "bg-primary text-white"
                              : "disabled bg-neutral-a5 text-neutral-a4"
                        }`}
                        onClick={() => confirmSelectedFreebies()}
                        disabled={lsFreebie.length === 0 ? true : false}
                        data-testid="confirm-button"
                     >
                        Confirm
                     </button>
                     <button
                        className={`w-full rounded-lg bg-white py-2 font-bold text-primary`}
                        onClick={() => changeFreebieCategory()}
                     >
                        Go back
                     </button>
                  </>
               )}
            </div>
         ) : (
            <div className="space-y-4 px-6 py-4">
               <button
                  className={`w-full rounded-lg py-2 font-bold ${
                     eFreebie.length !== 0
                        ? "bg-primary text-white"
                        : "disabled bg-neutral-a5 text-neutral-a4"
                  }`}
                  onClick={() => {
                     setIsFreebieConfirmed(true);
                     return navigate({
                        pathname: `/${mobileNumber}/prepaid`,
                        search: searchParams.toString(),
                     });
                  }}
                  disabled={eFreebie.length === 0 ? true : false}
               >
                  Confirm
               </button>
            </div>
         )}
      </div>
   );
};

/**
 * This utility function is used to generate the text block for the freebie catalog per category
 * @param {{data}} data - here is the determinant of where it is Entertainment of Lifestyle
 * @returns
 */
const FreebieBanner = ({ data }) => {
   const isEntertainment = data === "entertainment";

   const categoryText = isEntertainment ? "Entertainment" : "Lifestyle";
   const bannerText = isEntertainment
      ? "Select at least one (1) Entertainment freebie for your promo."
      : "Select at least one (1) Health & Savings freebie for your promo.";

   return (
      <div
         data-testid="freebie-banner"
         className="space-y-4 bg-transparent px-4 pb-5 pt-3"
      >
         <div className="flex items-center justify-between font-bold text-neutral-a0">
            <p data-testid="freebie-category-text">{categoryText}</p>
            <p>
               <span>1</span>
               <span className="text-neutral-a4">/1</span>
            </p>
         </div>

         <p data-testid="freebie-banner-text" className="text-xs">
            {bannerText}
         </p>
      </div>
   );
};

/**
 * This utility function generates the freebie card that contains all the freebie details
 * @param {*} param0
 * @returns
 */
const FreebieCatalogPanel = ({
   serviceId,
   freebieName,
   allocation,
   validity,
   apps,
   selectedFreebie,
   setterFn,
   type,
}) => {
   const boosterApps = getBoosterApps({ apps });
   const allocNVal = allocation && validity ? `${allocation}/${validity}` : "";

   const isFreebieCatalogSelected = selectedFreebie === serviceId;

   // Utitlity function that handles if the freebie is selected
   const isFreebieSelected = () => {
      setterFn(serviceId);
      setCache(type, freebieName);
   };

   return (
      <div
         data-testid={`freebie-${serviceId}`}
         className={`group space-y-4 rounded-lg px-4 pb-5 pt-3 shadow-lg hover:border hover:border-primary hover:bg-light-blue ${
            isFreebieCatalogSelected
               ? "border border-primary bg-light-blue"
               : "bg-white"
         }`}
         onClick={() => isFreebieSelected()}
      >
         <div
            data-testid={`freebie-${serviceId}-text-group`}
            className={`flex items-center justify-between font-bold group-hover:text-primary ${
               isFreebieCatalogSelected ? "text-primary" : "text-neutral-a0"
            }`}
         >
            <p data-testid={`freebie-${serviceId}-name`}>{freebieName}</p>
            <p data-testid={`freebie-${serviceId}-allocNVal`}>{allocNVal}</p>
         </div>

         <div className="flex gap-1">
            {boosterApps
               .filter((data) => BoosterAppIcons[data])
               .map((data) => (
                  <span
                     data-testid={`${serviceId}-appIcon-${data}`}
                     key={`${serviceId}-${data}`}
                     className="h-6 w-6"
                  >
                     <img src={BoosterAppIcons[data]} alt={`${data}-icon`} />
                  </span>
               ))}
         </div>
      </div>
   );
};

/**
 * The utility function that returns the container element for all the freebies
 * in defined category
 * @param {data} -  The category of the freebies to be displayed
 * @returns
 */
const DisplayFreebiesInCategory = ({
   type,
   freebies,
   eFreebie,
   fnEFreebie,
   lsFreebie,
   fnLsFreebie,
}) => {
   return (
      <div
         data-testid="freebie-panels"
         className="space-y-5 overflow-y-scroll px-4 pb-8 pt-4"
      >
         {freebies.map((data) => (
            <FreebieCatalogPanel
               type={type}
               key={data.record_id}
               serviceId={data.record_id}
               freebieName={data.freebie_name}
               allocation={data.freebie_allocation}
               validity={data.freebie_validity}
               apps={data.freebie_apps}
               selectedFreebie={type === "entertainment" ? eFreebie : lsFreebie}
               setterFn={type === "entertainment" ? fnEFreebie : fnLsFreebie}
            />
         ))}
         <EndVoucherMessage />
      </div>
   );
};

const EndVoucherMessage = () => {
   return (
      <div className="space-y-4 bg-transparent px-4 pb-5 pt-3">
         <div className="flex ">
            <span className="h-6 w-6">
               <img src={InfoIcon} alt={`Info here`} />
            </span>
            <p className="text-xs text-neutral-a0">
               You can view all your content vouchers within GlobeOne app.
            </p>
         </div>
      </div>
   );
};

/**
 * The function component for the entire freebie catalog page
 * @returns
 */
const FreebieCatalog = () => {
   const [freebieCategory, setFreebieCategory] = useState("entertainment");
   const [hasEntertainmentFreebies, setHasEntertainmentFreebies] =
      useState(true);
   const [hasLifestyleFreebies, setLifestyleFreebies] = useState(true);
   const [selectedEFreebie, setSelectedEFreebie] = useState("");
   const [selectedLsFreebie, setSelectedLsFreebie] = useState("");
   const [progressData, setProgressData] = useState(50);
   const [isFreebieConfirmed, setIsFreebieConfirmed] = useState(false);

   const entityId = useParams().entityId;

   const { entertainmentFreebies, lifestyleFreebies } =
      sortFreebiesById(entityId);

   useEffect(() => {
      // Check if there are any entertainment freebies
      if (entertainmentFreebies.length === 0) {
         setHasEntertainmentFreebies(false);
         setFreebieCategory("lifestyle");
      }
      if (lifestyleFreebies.length === 0) setLifestyleFreebies(false);

      let tempProgressData =
         freebieCategory === "entertainment" &&
         hasEntertainmentFreebies &&
         hasLifestyleFreebies
            ? 50
            : 100;
      setProgressData(tempProgressData);
   }, [entityId, entertainmentFreebies, lifestyleFreebies]);

   return (
      <div className="pancake-stack h-screen max-h-screen min-h-screen max-w-xl overscroll-none">
         <TopSection
            hasEFreebies={hasEntertainmentFreebies}
            hasLsFreebies={hasLifestyleFreebies}
            data={
               freebieCategory === "entertainment" &&
               hasEntertainmentFreebies &&
               hasLifestyleFreebies
                  ? 50
                  : 100
            }
            setData={setProgressData}
            setFreebieCategory={setFreebieCategory}
         ></TopSection>
         <ProgressBar data={progressData} data-testid="progressbar" />
         <FreebieBanner data={freebieCategory} />
         <DisplayFreebiesInCategory
            type={freebieCategory}
            freebies={
               freebieCategory === "entertainment"
                  ? entertainmentFreebies
                  : lifestyleFreebies
            }
            eFreebie={selectedEFreebie}
            fnEFreebie={setSelectedEFreebie}
            lsFreebie={selectedLsFreebie}
            fnLsFreebie={setSelectedLsFreebie}
            data-testid={
               selectedEFreebie.length === 0
                  ? "lifestyle-freebie"
                  : "entertainment-freebie"
            }
         />
         <BottomPanel
            fn={setFreebieCategory}
            data={freebieCategory}
            hasBoth={hasEntertainmentFreebies && hasLifestyleFreebies}
            eFreebie={selectedEFreebie}
            lsFreebie={selectedLsFreebie}
            setIsFreebieConfirmed={setIsFreebieConfirmed}
         ></BottomPanel>
      </div>
   );
};

export default FreebieCatalog;
