import React, { useEffect, useState } from "react";
import CloseIconSVG from "../assets/close-icon.svg";
import { cxsGetPaymentSessionByTokenPaymentId } from "../services/cxs";
import { getCache } from "../services/cache";

import { useNavigate, useSearchParams } from "react-router-dom";

function Failed() {
   const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();
   const [tokenPaymentId, setTokenPaymentId] = useState("");
   const [searchParams] = useSearchParams();
   const mobileNumber = searchParams.get("mobileNumber");
   const [selectedCatalog, setSelectedCatalog] = useState(
      getCache("ng1_bcf_selected_categorylog") ?? ""
   );

   const date = Date.now();
   const startDate = new Date(date);
   const endOfValidity = new Date(
      startDate.setDate(parseInt(selectedCatalog.validity))
   );

   useEffect(() => {
      setIsLoading(true);
      const retrieveSessionDetails = async () => {
         const cachedTokenPaymentId = getCache("ng1_bcf_token_payload_id");
         setTokenPaymentId(cachedTokenPaymentId);
         const paymentSession = await cxsGetPaymentSessionByTokenPaymentId(
            cachedTokenPaymentId
         );
         setIsLoading(false);
      };

      retrieveSessionDetails();
   }, [mobileNumber]);

   return (
      <div>
         <div
            className={`pancake-stack-v1 h-screen max-h-screen min-h-screen max-w-xl bg-receipt-bg ${
               isLoading ? "opacity-5" : ""
            } drop-shadow-sm`}
         >
            <div className="flex flex-row justify-between p-6">
               <div className="flex flex-col">
                  <p className="text-lg font-semibold text-white">
                     Here's the receipt!
                  </p>
                  <p className="text-xs font-semibold text-receipt-time">
                     {startDate.toDateString()}
                  </p>
               </div>
               <div className="flex items-center justify-center pt-3">
                  <img
                     src={CloseIconSVG}
                     onClick={() =>
                        navigate({
                           pathname: "/",
                           search: searchParams.toString(),
                        })
                     }
                     className="cursor-pointer"
                     alt="Go Back"
                  />
               </div>
            </div>
            <div className="pancake-stack-v1 m-auto flex w-11/12 flex-col border bg-white">
               <div className="mb-2 h-12">
                  {/* This container is meant for the frills design */}
               </div>
               <div className="flex flex-col">
                  {/* The success logo and the download logo */}
                  <div className="relative">
                     <div className="flex w-full grow items-center justify-center text-center text-6xl text-error">
                        &#x2716;
                     </div>
                     <div className="absolute right-6 top-0 w-7 flex-none">
                        {/* <img
                                    className="w-full"
                                    src={DownloadIcon}
                                    alt="Donwload receipt here"
                                /> */}
                     </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                     <p className="block text-lg font-semibold text-error">
                        Failed!
                     </p>
                     <p className="block text-sm text-neutral-a1">
                        Sorry your transaction has failed.
                     </p>
                     <p className="mt-1 block text-sm text-neutral-a1">
                        We emailed and texted you the confirmation.
                     </p>

                     <div className="flex w-10/12 items-center justify-between border-b border-neutral-a5 py-3">
                        <p className="text-md font-semibold text-neutral-a3">
                           Send to
                        </p>
                        <p className="text-md text-right font-medium text-neutral-a0">
                           +{mobileNumber}
                        </p>
                     </div>
                     <div className="flex w-10/12 items-center justify-between border-neutral-a4 pt-3">
                        <p className="text-md font-semibold text-neutral-a3">
                           Promo
                        </p>
                        <p className="text-md text-right font-medium text-neutral-a0">
                           {selectedCatalog.name}
                        </p>
                     </div>
                     <div className="flex w-10/12 items-center justify-between border-b border-neutral-a5 py-3">
                        <p className="text-md font-semibold text-neutral-a3">
                           Amount
                        </p>
                        <p className="text-md text-right font-semibold text-neutral-a0">
                           {`₱${selectedCatalog.price}.00`}
                        </p>
                     </div>
                     <div className="flex w-10/12 items-center justify-between border-b border-neutral-a5 py-3">
                        <div className="flex flex-col">
                           <p className="text-md font-semibold text-neutral-a3">
                              Refunded Amount
                           </p>
                           <p className="text-xs font-normal text-neutral-a3">
                              To GCash
                           </p>
                        </div>
                        <p className="text-right text-xl font-semibold text-neutral-a0">
                           {`₱${selectedCatalog.price}.00`}
                        </p>
                     </div>
                     <div className="flex w-10/12 items-center justify-between border-b border-neutral-a5 py-3">
                        <div className="flex flex-col">
                           <p className="text-md font-semibold text-neutral-a3">
                              Reference No.
                           </p>
                           <p className="text-md font-semibold text-neutral-a0">
                              {tokenPaymentId}
                           </p>
                        </div>
                        {/* <span className="flex items-center justify-center border rounded-full h-7 w-7">
                                    <img
                                        className=""
                                        src={CopyIcon}
                                        alt="Copy Reference number"
                                    />
                                </span> */}
                     </div>
                  </div>
               </div>
               <div className="mt-2 h-12">
                  {/* This container is meant for the frills design */}
               </div>
            </div>
            <div className="space-y-4 px-6 py-4">
               <button
                  className={`mb-12 w-full rounded-lg bg-primary py-2 font-bold text-white`}
                  onClick={() =>
                     navigate({
                        pathname: "/",
                        search: searchParams.toString(),
                     })
                  }
               >
                  Done
               </button>
            </div>
         </div>
         {isLoading ? (
            <div
               role="status"
               className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2"
            >
               <svg
                  aria-hidden="true"
                  className="mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                     fill="currentColor"
                  />
                  <path
                     d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                     fill="currentFill"
                  />
               </svg>
            </div>
         ) : (
            <></>
         )}
      </div>
   );
}

export default Failed;
